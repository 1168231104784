const messages = {
	en: {
		translations: {
			header: {
				greetings: {
					hello: "Hello",
					welcome: "Welcome to",
					activeUntil: "Active until"
				},
			},
			
			dashboard: {
				title: {
				  inservice: "IN SERVICE",
				  waiting: "WAITING FOR SERVICE",
				  onlineAgents: "ONLINE AGENTS",
				  completedTickets: "COMPLETED TICKETS",
				  totalSentMessages: "TOTAL SENT MESSAGES",
				  totalReceivedMessages: "TOTAL RECEIVED MESSAGES",
				  newLeads: "NEW LEADS",
				  averageHandlingTime: "AVERAGE HANDLING TIME",
				  averageWaitTime: "AVERAGE WAIT TIME",
				  totalUserInteractions: "TOTAL INTERACTIONS PER USER",
				  totalInteractions: "TOTAL INTERACTIONS",
				  startDate: "Start Date",
				  endDate: "End Date",
				  filter: "FILTER",
				  showFilterDate: "SEE SERVICES BY PERIOD",
          		  showFilterUser: "SEE SERVICES BY USER",
          		  hideFilter: "HIDE FILTER"
				}
			  },  
			messageVariablesPicker: {
				label: "Available Variables",
				vars: {
				  contactFirstName: "First Name",
				  contactName: "Name",
				  user: "Agent",
				  greeting: "Greeting",
				  protocolNumber: "Protocol",
				  date: "Date",
				  hour: "Hour",
				  ticket_id: "Ticket Number",
				  queue: "Department",
				  connection: "Connection"
				}
			  },
			  
			quickemessage: {
				toasts: {
				  success: "Shortcut added successfully!",
				  deleted: "Shortcut removed successfully!",
				},
				title: "Quick Responses",
				buttons: {
				  add: "New Response",
				},
				dialog: {
				  add: "Add",
				  edit: "Edit",
				  form: {
					shortcode: "Shortcut",
					message: "Message",
					global: "Global",
				  },
				  buttons: {
					cancel: "Cancel",
					edit: "Save",
					attach: "Attach",
					add: "Save",
				  },
				},
				confirmationModal: {
				  deleteTitle: "Deletion",
				  deleteMessage: "This action is irreversible! Do you want to proceed?",
				},
			  },
			  
			quickemessages: {
				toasts: {
				  success: "Shortcut added successfully!",
				  deleted: "Shortcut removed successfully!",
				},
				title: "Quick Responses",
				table: {
				  shortcode: "Shortcut",
				  mediaName: "File",
				  status: "Global",
				  actions: "Action",
				},
				searchPlaceholder: "Search",
				buttons: {
				  add: "Add",
				  attach: "Attach File",
				  cancel: "Cancel",
				  edit: "Save",
				},
				confirmationModal: {
				  deleteTitle: "Deletion",
				  deleteMessage: "This action is irreversible! Do you want to proceed?",
				},
			  },
			  
			signup: {
				title: "Sign up",
				toasts: {
					success: "User created successfully! Please login!",
					fail: "Error creating user. Check the reported data.",
				},
				form: {
					name: "Name",
					email: "Email",
					password: "Password",
					company: "Company Name",
					phone: "WhatsApp Number"
				},
				buttons: {
					submit: "Register",
					login: "Already have an account? Log in!",
				},
			},
			login: {
				title: "Login",
				form: {
					email: "Email",
					password: "Password",
				},
				buttons: {
					submit: "Enter",
					register: "Don't have an account? Register!",
				},
			},
			forgotpassword: {
				toasts: {
				  	success: "Email successfully sent!",
				  	error: "Email not found!",
				},
				passwordRules: "Your password must have at least 8 characters, including an uppercase letter, a lowercase letter and a number.",
			  },
			smtpCredentials: {
				toasts: {
					error: "An error has occurred. SMTP credentials.",
				},
			},
			auth: {
				toasts: {
					success: "Successful login!!",
				},
				dueDate: {
					expiration: "Your subscription expires in",
					days: "days!",
					day: "day!",
					expirationToday: "Your subscription expires today!",
				},
				token: "Token",
			},
			companies: {
				title: "Company Registry",
				form: {
					name: "Company Name",
					plan: "Plan",
					token: "Token",
					submit: "Create",
					success: "Company created successfully!",
				},
			},
			connections: {
				title: "Connections",
				toasts: {
					deleted: "WhatsApp connection deleted sucessfully!",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage: "Are you sure? It cannot be reverted.",
					disconnectTitle: "Disconnect",
					disconnectMessage: "Are you sure? You'll need to read QR Code again.",
				},
				buttons: {
					add: "Add WhatsApp",
					disconnect: "Disconnect",
					tryAgain: "Try Again",
					qrcode: "QR CODE",
					newQr: "New QR CODE",
					connecting: "Connectiing",
				},
				toolTips: {
					disconnected: {
						title: "Failed to start WhatsApp session",
						content: "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
					},
					qrcode: {
						title: "Waiting for QR Code read",
						content: "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
					},
					connected: {
						title: "Connection established",
					},
					timeout: {
						title: "Connection with cell phone has been lost",
						content: "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
					},
				},
				table: {
					name: "Name",
					status: "Status",
					lastUpdate: "Last Update",
					default: "Default",
					actions: "Actions",
					session: "Session",
				},
			},
			whatsappModal: {
				title: {
				  add: "Add WhatsApp",
				  edit: "Edit WhatsApp",
				},
				form: {
				  name: "Name",
				  default: "Default",
				  maxUseBotQueues: "Maximum number of times the chatbot will be sent",
				  expiresTicket: "Close open chats after x hours",
				  outOfHoursMessage: "Out of hours message",
				  greetingMessage: "Greeting message",
				  complationMessage: "Completion message",
				  token: "Token for external integration",
				},
				buttons: {
				  okAdd: "Add",
				  okEdit: "Save",
				  cancel: "Cancel",
				},
				success: "WhatsApp saved successfully.",
			},
			qrCode: {
				message: "Read QrCode to start the session",
			},
			contacts: {
				title: "Contacts",
				toasts: {
					deleted: "Contact deleted sucessfully!",
				},
				searchPlaceholder: "Search ...",
				confirmationModal: {
					deleteTitle: "Delete",
					importTitlte: "Import contacts",
					deleteMessage:
						"Are you sure you want to delete this contact? All related tickets will be lost.",
					importMessage: "Do you want to import all contacts from the phone?",
				},
				buttons: {
					import: "Import Contacts",
					add: "Add Contact",
				},
				table: {
					name: "Name",
					whatsapp: "WhatsApp",
					email: "Email",
					actions: "Actions",
				},
			},
			contactModal: {
				title: {
					add: "Add contact",
					edit: "Edit contact",
				},
				form: {
					mainInfo: "Contact details",
					extraInfo: "Additional information",
					name: "Name",
					number: "Whatsapp number",
					email: "Email",
					extraName: "Field name",
					extraValue: "Value",
				},
				buttons: {
					addExtraInfo: "Add information",
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "Contact saved successfully.",
			},
			queueModal: {
				title: {
					add: "Add queue",
					edit: "Edit queue",
				},
				form: {
					name: "Name",
					color: "Color",
					greetingMessage: "Greeting Message",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
			},
			userModal: {
				title: {
				  add: "Add User",
				  edit: "Edit User",
				},
				form: {
				  name: "Name",
				  email: "Email",
				  password: "Password",
				  farewellMessage: "Farewell Message",
				  profile: "Profile",
				  whatsapp: "Default Connection",
				  startWork: "Start of Work",
				  endWork: "End of Work"
				},
				buttons: {
				  okAdd: "Add",
				  okEdit: "Save",
				  cancel: "Cancel",
				},
				success: "User saved successfully.",
			  },			  
			chat: {
				noTicketMessage: "Select a ticket to start chatting.",
			},
			ticketsManager: {
				buttons: {
					newTicket: "New",
				},
			},
			ticketsQueueSelect: {
				placeholder: "Queues",
			},
			tickets: {
				toasts: {
					deleted: "The ticket you were on has been deleted.",
				},
				notification: {
					message: "Message from",
				},
				tabs: {
					open: { title: "Inbox" },
					closed: { title: "Resolved" },
					group: { title: "Groups" },
					search: { title: "Search" },
				},
				search: {
					placeholder: "Search tickets and messages.",
				},
				buttons: {
					showAll: "All",
				},
			},
			transferTicketModal: {
				title: "Transfer Ticket",
				fieldLabel: "Type to search for users",
				fieldQueueLabel: "Transfer to queue",
				fieldQueuePlaceholder: "Select a queue",
				noOptions: "No users found with that name",
				buttons: {
				  ok: "Transfer",
				  cancel: "Cancel",
				},
			  },
			  
			ticketsList: {
				pendingHeader: "Queue",
				assignedHeader: "Working on",
				noTicketsTitle: "Nothing here!",
				noTicketsMessage: "No tickets found with this status or search term.",
				buttons: {
					accept: "Accept",
				},
			},
			newTicketModal: {
				title: "Create Ticket",
				fieldLabel: "Type to search for a contact",
				fieldQueuePlaceholder: "Select a queue",
				add: "Add",
				buttons: {
					ok: "Save",
					cancel: "Cancel",
				},
			},
			mainDrawer: {
				listItems: {
					dashboard: "Dashboard",
					connections: "Connections",
					tickets: "Tickets",
					quickMessages: "Quick Messages",
					contacts: "Contacts",
					queues: "Queues",
					tags: "Tags",
					administration: "Administration",
					companies: "Companies",
					users: "Users",
					settings: "Settings",
					ratings: "Ratings",
					helps: "Ajuda",
					messagesAPI: "API",
					schedules: "Schedules",
					campaigns: "Campaigns",
					annoucements: "Annoucements",
					chats: "Internal Chat",
					financeiro: "Finance",
					logout: "Logout",
					version: "Version",
					toDoList: "Tasks",
					panel: "Panel",
					lanes: "Boards",
				},
				appBar: {
					user: {
						profile: "Profile",
						logout: "Logout",
					},
				},
			},
			notifications: {
				noTickets: "No notifications.",
			},
			queues: {
				title: "Queues",
				table: {
					name: "Name",
					color: "Color",
					greeting: "Greeting message",
					actions: "Actions",
				},
				buttons: {
					add: "Add queue",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage:
						"Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
				},
			},
			queueSelect: {
				inputLabel: "Queues",
			},
			users: {
				title: "Users",
				table: {
				  id: "ID",
				  status: "Status",
				  name: "Name",
				  email: "Email",
				  profile: "Profile",
				  whatsapp: "Default Connection",
				  startWork: "Start of Work",
				  endWork: "End of Work",
				  actions: "Actions",
				},
				buttons: {
				  add: "Add User",
				},
				toasts: {
				  deleted: "User deleted successfully.",
				},
				confirmationModal: {
				  deleteTitle: "Delete",
				  deleteMessage:
					"All data of the user will be lost. Open support requests of this user will be moved to the queue.",
				},
			  },
			  
			settings: {
				options: {
					title: "Options",
					enabled: "Enabled",
					disabled: "Enabled",
					officeScheduling: "Office Scheduling",
					queueSectorManagement: "Queue/Sector Management",
					managementByCompany: "Management by Company",
					sendGreetingAcceptingService: "Send Greeting When Accepting Service",
					chooseRandomOperatorWhenChoosingSector: "Choose Random Operator When Choosing Sector",
					sendSectorAttendantTransferMessage: "Send Sector/Attendant Transfer Message",
					ignoreGroupMessages: "Ignore group messages",
					botType: "Bot Type",
					notAcceptCallsOnWhatsApp: "Inform that you do not accept calls on WhatsApp",
					allowsAttendantChooseSendSignature: "Allows attendant to choose SEND Signature",
					sendGreetingWhenOneSector: "Send greeting when there is only 1 sector",
					sendMessageWithQueuePosition: "Send message with queue position",
					sendFarewellMessageWhenWaiting: "Send farewell message when waiting",
					agreeReceiveAudioFromAllContacts: "Agree to receive audio from all contacts",
					wordRestartFlow: "Word To Restart Flow",
				},
				success: "Settings saved successfully.",
				title: "Settings",
				settings: {
					userCreation: {
						name: "User creation",
						options: {
							enabled: "Enabled",
							disabled: "Disabled",
						},
					},
				},
			},
			messagesList: {
				header: {
					assignedTo: "Assigned to:",
					dialogRatingTitle: "Do you want to leave a customer service review?",
					dialogClosingTitle: "End Conversation with Customer",
					dialogRatingCancel: "Resolve with goodbye message",
					dialogRatingSuccess: "Solve and submit Grade",
					dialogRatingWithoutFarewellMsg: "Solve without goodbye message",
					ratingTitle: "Choose a Rating menu",
					buttons: {
						return: "Return",
						resolve: "Resolve",
						reopen: "Reopen",
						accept: "Accept",
					},
				},
				deleted: "This message was deleted by the Contact.",
				metaPolice: "You have 24 hours to respond after receiving a message, following Meta's policies.",
				missedCall: "Missed voice/video call at",
				greetingsLead: "Greetings to the new Lead!",
			},
			messagesInput: {
				placeholderOpen: "Type a message",
				placeholderClosed: "Reopen or accept this ticket to send a message.",
				signMessage: "Sign",
			},
			contactDrawer: {
				header: "Contact details",
				buttons: {
					edit: "Edit contact",
				},
				extraInfo: "Other information",
			},
			ticketOptionsMenu: {
				delete: "Delete",
				transfer: "Transfer",
				confirmationModal: {
					title: "Delete ticket #",
					titleFrom: "from contact ",
					message: "Attention! All ticket's related messages will be lost.",
				},
				buttons: {
					delete: "Delete",
					cancel: "Cancel",
				},
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancel",
				},
			},
			messageOptionsMenu: {
				delete: "Delete",
				reply: "Reply",
				confirmationModal: {
					title: "Delete message?",
					message: "This action cannot be reverted.",
				},
			},
			backendErrors: {
				ERR_NO_OTHER_WHATSAPP:
					"There must be at lest one default WhatsApp connection.",
				ERR_NO_DEF_WAPP_FOUND:
					"No default WhatsApp found. Check connections page.",
				ERR_WAPP_NOT_INITIALIZED:
					"This WhatsApp session is not initialized. Check connections page.",
				ERR_WAPP_CHECK_CONTACT:
					"Could not check WhatsApp contact. Check connections page.",
				ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
				ERR_WAPP_DOWNLOAD_MEDIA:
					"Could not download media from WhatsApp. Check connections page.",
				ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
				ERR_SENDING_WAPP_MSG:
					"Error sending WhatsApp message. Check connections page.",
				ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
				ERR_OTHER_OPEN_TICKET:
					"There's already an open ticket for this contact.",
				ERR_SESSION_EXPIRED: "Session expired. Please login.",
				ERR_USER_CREATION_DISABLED:
					"User creation was disabled by administrator.",
				ERR_NO_PERMISSION: "You don't have permission to access this resource.",
				ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
				ERR_NO_SETTING_FOUND: "No setting found with this ID.",
				ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
				ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
				ERR_NO_USER_FOUND: "No user found with this ID.",
				ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
				ERR_CREATING_MESSAGE: "Error while creating message on database.",
				ERR_CREATING_TICKET: "Error while creating ticket on database.",
				ERR_FETCH_WAPP_MSG:
					"Error fetching the message in WhtasApp, maybe it is too old.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS:
					"This color is already in use, pick another one.",
				ERR_WAPP_GREETING_REQUIRED:
					"Greeting message is required if there is more than one queue.",
			},
			toDoList: {
				newTask: "New Task",
				description: "Description",
				errorCreatingTask: "Error creating the task.",
				errorUpdatingTask: "Error updating task.",
				errorDeletingTask: "Error deleting the task",
				errorSearchingTasks: "Error when searching for tasks. Try again later.",
				networkErrorFetchingTasks: "Network error when fetching tasks. Check your internet connection."
			},
			commom: {
				title: "Title",
				text: "Text",
				add: "Add",
				edit: "Edit",
				delete: "Delete",
				save: "Save",
				close: "Close",
				new: "New",
				cancel: "Cancel",
				done: "Done",
				confirm: "Confirm",
				name: "Name",
				action: "Action",
				actions: "Actions",
				search: "Search",
				opened: "Opened",
				no: "No",
				yes: "Yes",
				user: "User",
				users: "Users",
				connection: "Connection",
				connections: "Connections",
				queue: "Queue",
				queues: "Queues",
				updating: "Updating",
				view: "View",
				requiredField: "Required field",
			},

			"Login": "Login",
      "Name": "Name",
			"Email": "Email",
			"Password": "Password",
      "Phone": "Phone",
			"Enter": "Enter",
			"Send": "Send",
			"Recover Password?": "Recover Password?",
			"Recover Password": "Recover Password",
			"Required field": "Required field",
			"Don't have an account? Register!": "Don't have an account? Register!",
			"Verification Code": "Verification Code",
			"New Password": "New Password",
			"Confirm the Password": "Confirm the Password",
			"Support": "Support",
			"Password reset successfully": "Password reset successfully",
			"Invalid email": "Invalid email",
			"Passwords do not match": "Passwords do not match",
			"Assigned to": "Assigned to",
			"Spy Conversation": "Spy Conversation",
      "No queue": "No queue",
      "closed": "closed",
      "date_format": "MM/dd/yyyy",
      "date_format_moment": "MM/DD/YYYY",
      "hour_format": "HH:mm",
      "No registry": "No registry",
      "Your browser does not support the video element": "Your browser does not support the video element",
      "This company does not have permission to access this page! We are redirecting you": "This company does not have permission to access this page! We are redirecting you",
      "Are you sure? All built-in options will also be deleted":"Are you sure? All built-in options will also be deleted",
      "Plan": "Plan",
      "Plans": "Plans",
      "Active": "Active",
      "Yes": "Yes",
      "No": "No",
      "Document": "Document",
      "Price": "Price",
      "Due Date": "Due date",
      "Last Login": "Last Login",
      "Created at": "Created at",
      "Recurrence": "Recurrence",
      "Monthly": "Monthly",
      "Bimonthly": "Bimonthly",
      "Quarterly": "Quarterly",
      "Semiannual": "Semiannual",
      "Annual": "Annual",
      "Clear": "Clear",
      "Delete": "Delete",
      "Date": "Date",
      "User": "User",
      "Users": "Users",
      "Save": "Save",
      "Increment Due Date": "Increment Date",
      "Updated successfully": "Updated successfully",
      "Saved successfully": "Saved successfully",
      "Deleted Successfully": "Deleted Successfully",
      "Unable to complete this action": "Unable to complete this action",
      "Do you really want to delete?": "Do you really want to delete?",
      "Unable to load list": "Unable to load list",
      "Check if a company with the same name already exists or if the fields have been filled in correctly": "Check if a company with the same name already exists or if the fields have been filled in correctly",
      "Connection": "Conection",
      "Connections": "Conections",
      "Queue": "Queue",
      "Queues": "Queues",
      "Enabled": "Enabled",
      "Disabled": "Disabled",
      "Campaign": "Campaign",
      "Campaigns": "Campaigns",
      "Schedule": "Schedule",
      "Schedules": "Schedules",
      "Internal Chat": "Internal Chat",
      "External API": "External API",
      "Company": "Company",
      "Companies": "Companies",
      "Help": "Help",
      "Timetables updated successfully": "Timetables updated successfully",
      "Add Company/User": "Add Company/User",
      "Video Code": "Video Code",
      "Title": "Title",
      "Description": "Description",
      "Listing": "Listing",
      "Settings": "Settings",
      "Contact Lists": "Contact Lists",
	  "day": "day",
	  "days": "days",
      "Day of the Week": "Day of the Week",
      "Start Time": "Start Time",
      "End Time": "End Time",
      "Monday": "Monday",
      "Tuesday": "Tuesday",
      "Wednesday": "Wednesday",
      "Thursday": "Thursday",
      "Friday": "Friday",
      "Saturday": "Saturday",
      "Sunday": "Sunday",
      "Message": "Message",
      "The message is mandatory to move to the next level": "The message is mandatory to move to the next level",
      "If the message is not defined, the bot will not follow you": "If the message is not defined, the bot will not follow you",
      "File": "File",
      "Service Hours": "Service Hours",
      "Queue Data": "Queue Data",
      "Click save to record the changes": "Click save to record the changes",
      "Option not saved, save option before adding a file": "Option not saved, save option before adding a file",
      "File added": "File added",
      "Queue saved successfully!": "Queue saved successfully!",
      "Too Short!": "Too Short!",
      "Too Long!": "Too Long!",
      "Add options": "Add options",
      "Documentation for sending messages": "Documentation for sending messages",
      "Sending Methods": "Sending Methods",
      "Text Messages": "Text Messages",
      "Media Messages": "Media Messages",
      "Instructions": "Instructions",
      "Important Notes": "Important Notes",
      "Before sending messages, it is necessary to register the token linked to the connection that will send the messages": "Before sending messages, it is necessary to register the token linked to the connection that will send the messages",
      "To register, access the Connections menu, click on the connection's edit button and insert the token in the appropriate field": "To register, access the Connections menu, click on the connection's edit button and insert the token in the appropriate field",
      "The sending number must not have a mask or special characters and must be composed of": "The sending number must not have a mask or special characters and must be composed of",
      "Country Code": "Country Code",
      "Number": "Number",
      "Below is the list of information needed to send text messages": "Below is the list of information needed to send text messages",
      "Below is the list of information needed to send media messages": "Below is the list of information needed to send media messages",
      "Sending Test": "Sending Test",
      "Method": "Method",
      "Registered token": "Registered token",
      "text": "text",
      "subtitle": "subtitle",
      "Body": "Body",
      "Caption": "Caption",
      "Invalid Email!": "Invalid Email!",
      "Updating": "Updating",
      "Shows all Messages for the User Profile": "Shows all Messages for the User Profile",
      "Disable chatbot for this contact": "Disable chatbot for this contact",
      "Import / Export": "Import / Export",
      "Import from WhatsApp": "Import from WhatsApp",
      "Import from Excel": "Import from Excel",
      "Logo updated successfully!": "Logo updated successfully!",
	  "Background image updated successfully!": "Background image updated successfully!",
      "Invalid file. Please upload a JPG, JPEG or PNG file": "Invalid file. Please upload a JPG, JPEG or PNG file",
      "Please upload a file less than 1 MB": "Please upload a file less than 1 MB",
      "Integrations": "Integrations",
      "This integration is disabled": "This integration is disabled",
      "Successfully enabled": "Successfully enabled",
      "Successfully disabled": "Successfully disabled",
      "The recommended image size is": "The recommended image size is",
      "Shows Signup Link on Login/Reset Password Page": "Shows Signup Link on Login/Reset Password Page",
      "Background Image on Login Page": "Background Image on Login Page",
	  "Your subscription expires in": "Your subscription expires in",
	  "Oops! Your subscription expired on": "Oops! Your subscription expired on",
	  "Contact Support for more information!": "Contact Support for more information!",
      "": "",

			internalChat: "Internal Chat",
			service: "Service",
			services: "Services",
			durationServices: "Duration of Services",
			groupService: "Group Service",
			resetConnection: "Reset Connections",
			callSupport: "Call Support",
			newConnection: "New Connection",
			filterByUsers: "Filter by Users",
			companyNoPermissionAccessPage: "This company does not have permission to access this page! We are redirecting.",
			deleteConversation: "Delete Conversation",
			confirmAction: "This action cannot be reversed. Do you want to confirm?",
			newLane: "New Board",
			invoice: "Invoice",
			invoices: "Invoices",
			detail: "Detail",
			details: "Details",
			price: "Price",
			prices: "Prices",
			dueDate: "Due date",
		},
	},
};

export { messages };
