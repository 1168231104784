import React, { useEffect, useState } from "react";
import qs from "query-string";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import moment from "moment";
import { toast } from 'react-toastify'; 
import toastError from '../../errors/toastError';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.type === 'dark' ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)", // Fundo semi-transparente
    borderRadius: "35px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "6px solid transparent", // Adiciona uma borda transparente
    boxShadow: "0 0 180px rgba(0, 0, 0, 0.3)", // Adiciona um efeito de sombra azul
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  powered: {
    color: "white",
  },
  logo: {
    marginBottom: theme.spacing(2), // Espaço entre o logo e o título
    width: "220px", // ou a largura desejada
    height: "auto", // ou a altura desejada
  },
}));

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const ForgetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  let companyId = null;
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showResetPasswordButton, setShowResetPasswordButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(""); // Estado para mensagens de erro
  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("");
  const [showSignupLink, setShowSignupLink] = useState("enabled");
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = { email: "" };

  const [user] = useState(initialState);
  const dueDate = moment().add(3, "day").format();

  const handleSendEmail = async (values) => {
    const email = values.email;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/forgetpassword/${email}`
      );
      console.log("API Response:", response.data);

      if (response.status === 201) {
        if (response.data.error === false) {
          setShowAdditionalFields(true)
          setShowResetPasswordButton(true);
          toast.success(i18n.t("forgotpassword.toasts.success"));
        } else {
          toast.error("Error");
        }
      }
    } catch (err) {
      setShowAdditionalFields(false)
      toastError(err);
    }
  };

  const handleResetPassword = async (values) => {
    const email = values.email;
    const token = values.token;
    const newPassword = values.newPassword;
    const confirmPassword = values.confirmPassword;

    if (newPassword === confirmPassword) {
      try {
        const response = await api.post(
          `${process.env.REACT_APP_BACKEND_URL}/resetpasswords/${email}/${token}/${newPassword}`
        );

        console.log("response: ", response.data)

        if (response.status === 201) {
          setError(""); // Limpe o erro se não houver erro
          toast.success(i18n.t("Password reset successfully"));
          history.push("/");
        }
      } catch (err) {
        console.log("API Reset Password Error:", err);
        toastError(err);
      }
    }
  };

  const isResetPasswordButtonClicked = showResetPasswordButton;
  const UserSchema = Yup.object().shape({
    email: Yup.string().email(i18n.t("Invalid email")).required(i18n.t("Required field")),
    newPassword: isResetPasswordButtonClicked
      ? Yup.string()
          .required(i18n.t("Required field"))
          .matches(
            passwordRegex, i18n.t("forgotpassword.passwordRules")
          )
      : Yup.string(), // Sem validação se não for redefinição de senha
    confirmPassword: Yup.string().when("newPassword", {
      is: (newPassword) => isResetPasswordButtonClicked && newPassword,
      then: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], i18n.t("Passwords do not match"))
        .required(i18n.t("Required field")),
      otherwise: Yup.string(), // Sem validação se não for redefinição de senha
    }),
  });

  useEffect(() => {
    // Logo
    const fetchLogo = async () => {
      try {
        const { data } = await api.get("/settings/logo/1/noAuth");
        setLogo(data.value);
      } catch (err) {
        toastError(err);
      }
    };
    fetchLogo();

    // BackgroundImage
    const fetchBackgroundImage = async () => {
      try {
        const {data} = await api.get("/settings/bgImage/1/noAuth");

        setBackground(data.value);
      } catch (err) {
        toastError(err);
      }
    };
    fetchBackgroundImage();
      
    // Show Signup Link
    const fetchShowSignupLink = async () => {
      try {
        const showSignupLinkSett = await api.get("/settings/showSignupLink/1/noAuth");
        const showSignupLinkSettValue = showSignupLinkSett.data.value
        let SignupLinkBool = "disabled";
        
        if (showSignupLinkSett) {
          if (showSignupLinkSettValue === "enabled") {
            SignupLinkBool = "enabled"
          } else {
            SignupLinkBool = "disabled"
          }
          setShowSignupLink(SignupLinkBool);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchShowSignupLink();
  }, []);

  const bgStyles = makeStyles(() => ({
    root: {
      width: "100vw",
      height: "100vh",
      backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL + '/public/media/' + background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  }));

  const bgClass = bgStyles();

  return (
    <div className={bgClass.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {logo != null && (
            <img className={classes.logo} src={process.env.REACT_APP_BACKEND_URL + '/public/media/' + logo} alt={process.env.REACT_APP_NAME_SYSTEM} />
          )}
          <Typography component="h1" variant="h5">
            {i18n.t("Recover Password")}
          </Typography>
          <Formik
            initialValues={{
              email: "",
              token: "",
              newPassword: "",
              confirmPassword: "",
            }}
            enableReinitialize={true}
            validationSchema={UserSchema}
            onSubmit={(values, actions) => {

              setTimeout(() => {
                if (showResetPasswordButton) {
                  console.log("handleResetPassword")
                  handleResetPassword(values);
                } else {
                  console.log("handleSendEmail")
                  handleSendEmail(values);
                }
                actions.setSubmitting(false);
                // toggleAdditionalFields();
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      id="email"
                      label={i18n.t("Email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="email"
                      required
                    />
                  </Grid>
                  {showAdditionalFields && (
                    <>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          variant="outlined"
                          fullWidth
                          id="token"
                          label={i18n.t("Verification Code")}
                          name="token"
                          error={touched.token && Boolean(errors.token)}
                          helperText={touched.token && errors.token}
                          autoComplete="off"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          variant="outlined"
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          id="newPassword"
                          label={i18n.t("New Password")}
                          name="newPassword"
                          error={
                            touched.newPassword &&
                            Boolean(errors.newPassword)
                          }
                          helperText={
                            touched.newPassword && errors.newPassword
                          }
                          autoComplete="off"
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          variant="outlined"
                          fullWidth
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          label={i18n.t("Confirm the Password")}
                          name="confirmPassword"
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword &&
                            errors.confirmPassword
                          }
                          autoComplete="off"
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={toggleConfirmPasswordVisibility}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                {showResetPasswordButton ? (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {i18n.t("Recover Password")}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {i18n.t("Enter")}
                  </Button>
                )}
                { showSignupLink === "enabled" &&  (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      component={RouterLink}
                      to="/signup"
                    >
                      {i18n.t("Don't have an account? Register!")}
                    </Link>
                  </Grid>
                </Grid>
                )}
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      component={RouterLink}
                      to="/login"
                    >
                      {i18n.t("Login")}
                    </Link>
                  </Grid>
                </Grid>
                {error && (
                  <Typography variant="body2" color="error">
                    {error}
                  </Typography>
                )}
              </Form>
            )}
          </Formik>
        </div>
        <Box mt={5} />
      </Container>
    </div>
  );
};

export default ForgetPassword;
