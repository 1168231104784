import React, { useEffect, useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { i18n } from "../../translate/i18n";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.type === 'dark' ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)", // Fundo semi-transparente
    borderRadius: "35px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "6px solid transparent", // Adiciona uma borda transparente
    boxShadow: "0 0 180px rgba(0, 0, 0, 0.3)", // Adiciona um efeito de sombra azul
    
  },
  whatsappButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  whatsappIcon: {
    fontSize: 60, // ajuste o tamanho conforme necessário
    color: "#00826a",
    transition: "box-shadow 0.3s ease-in-out", // adiciona uma transição suave
    "&:hover": {
      color: "#469f44",
      boxShadow: "0 0 10px rgba(0, 130, 106, 0.7)", // adiciona um brilho sutil
    },
  },
  tooltip: {
    fontSize: 14,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: "220px", // ou a largura desejada
    height: "auto", // ou a altura desejada
  },
  passwordIcon: {
    cursor: "pointer",
  },
}));

const Login = () => {
  const classes = useStyles();
  
  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("");
  const [showSignupLink, setShowSignupLink] = useState("enabled");
  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
	
	useEffect(() => {
    // Logo
		const fetchLogo = async () => {
			try {
				const { data } = await api.get("/settings/logo/1/noAuth");
				setLogo(data.value);
			} catch (err) {
				toastError(err);
			}
		};
		fetchLogo();

    // BackgroundImage
    const fetchBackgroundImage = async () => {
      try {
        const {data} = await api.get("/settings/bgImage/1/noAuth");

        setBackground(data.value);
      } catch (err) {
        toastError(err);
      }
    };
    fetchBackgroundImage();
    
    // Show Signup Link
    const fetchShowSignupLink = async () => {
      try {
        const showSignupLinkSett = await api.get("/settings/showSignupLink/1/noAuth");
        const showSignupLinkSettValue = showSignupLinkSett.data.value
        let SignupLinkBool = "disabled";
        
        if (showSignupLinkSett) {
          if (showSignupLinkSettValue === "enabled") {
            SignupLinkBool = "enabled"
          } else {
            SignupLinkBool = "disabled"
          }
          setShowSignupLink(SignupLinkBool);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchShowSignupLink();
	}, []);

  const bgStyles = makeStyles(() => ({
    container: {
      position: "relative",
      background: `url(${process.env.REACT_APP_BACKEND_URL + '/public/media/' + background}) center/cover no-repeat`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
  }));

  const bgClass = bgStyles();

  return (
    <div className={bgClass.container}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {logo != null && (
            <img className={classes.logo} src={process.env.REACT_APP_BACKEND_URL + '/public/media/' + logo} alt={process.env.REACT_APP_NAME_SYSTEM} />
          )}
          <Typography component="h1" variant="h5">
            {i18n.t("Login")}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handlSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18n.t("Email")}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
            />
           <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("Password")}
              type={showPassword ? "text" : "password"}
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.passwordIcon}
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  component={RouterLink}
                  to="/forgetpsw"
                >
                  {i18n.t("Recover Password?")}
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {i18n.t("Enter")}
            </Button>
            { showSignupLink === "enabled" &&  (
              <>
              <Grid container>
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    component={RouterLink}
                    to="/signup"
                  >
                    {i18n.t("login.buttons.register")}
                  </Link>
                </Grid>
              </Grid>
              </>
            )}
          </form>
          <Tooltip title={i18n.t("Support")} classes={{ tooltip: classes.tooltip }}>
            <IconButton
              href={`https://wa.me/${process.env.REACT_APP_NUMBER_SUPPORT}`}
              className={classes.whatsappButton}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon className={classes.whatsappIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </Container>
    </div>
  );
};

export default Login;




