import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: theme.palette.text.primary,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  inputRow: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  input: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  sectionHeading: {
    fontSize: theme.typography.pxToRem(18), // ou qualquer tamanho desejado
    fontWeight: theme.typography.fontWeightBold,
    // Outras propriedades de estilo, se necessário
  },
}));

const BoardSettingsModal = ({ open, onClose }) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [isWebhookEnabled, setIsWebhookEnabled] = useState("false");
  const [typebotUrl, setTypebotUrl] = useState("");
  const [isTypebotEnabled, setIsTypebotEnabled] = useState("false");
  const [typebotName, setTypebotName] = useState("");
  const [recurrentMessage, setRecurrentMessage] = useState("");
  const [isRecurrentMessageEnabled, setIsRecurrentMessageEnabled] = useState("false");
  const [recurrentMessageDays, setRecurrentMessageDays] = useState("1");

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      setTags(fetchedTags);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTagInfo = async (tagId) => {
    try {
      const response = await api.get(`/tags/${tagId}`);
      const tagInfo = response.data; // Modifique conforme a estrutura da resposta
      setWebhookUrl(tagInfo.n8nUrC || "");
      setIsWebhookEnabled(tagInfo.n8nOnnC || "false");
      setTypebotUrl(tagInfo.typeUrC || ""); // Adicionei esta linha
      setTypebotName(tagInfo.typeNamC || "");
      setIsTypebotEnabled(tagInfo.typeOnnC || "false");
      setRecurrentMessage(tagInfo.msgR || "");
      setIsRecurrentMessageEnabled(tagInfo.msgRonn || "false");
      setRecurrentMessageDays(tagInfo.dayR || "1");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchTags();
    }
  }, [open]);

  const handleTagClick = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag(null);
    } else {
      setSelectedTag(tag);
      fetchTagInfo(tag.id);
    }
  };

  const handleSave = async () => {
    try {
      if (selectedTag) {
        const tagData = {
          n8nUrC: webhookUrl,
          n8nOnnC: isWebhookEnabled,
          typeOnnC: isTypebotEnabled,
          typeUrC: typebotUrl, // Corrigi para usar typebotUrl
          typeNamC: typebotName,
          msgR: recurrentMessage,
          dayR: recurrentMessageDays,
          msgRonn: isRecurrentMessageEnabled,
        };

        await api.put(`/tags/${selectedTag.id}`, tagData);

        await api.post("/tags/log-request", tagData);

        // Restante do código (pode incluir uma mensagem de sucesso, atualização do estado local, etc.)

        // Fechar o modal
        onClose();
      }
    } catch (error) {
      console.error('Erro ao salvar configurações:', error.message);
      // Adicionar lógica para lidar com erros, se necessário
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {/* Seção: Título do Modal */}
          <h2>Configurações do Quadro</h2>

          {/* Seção: Listagem de Tags */}
          <div>
            <h3>Listagem de Tags Kanban</h3>
            <List component="nav">
              {tags.map((tag) => (
                <div key={tag.id}>
                  {/* Item da Lista de Tags */}
                  <ListItem button onClick={() => handleTagClick(tag)}>
                    <ListItemText primary={tag.name} />
                    {selectedTag === tag ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  {/* Detalhes da Tag (expansível) */}
                  <Collapse in={selectedTag === tag} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* Seção: Configurações do Typebot */}
                      <h4 className={classes.sectionHeading}>Campanha Via Typebot</h4>
                      <div className={classes.inputContainer}>
                        <div className={classes.inputRow}>
                          <Divider className={classes.divider} />
                          
                          {/* ... (Campos do Typebot) */}
                          <TextField
                            className={classes.input}
                            label="Viewer do Typebot"
                            variant="outlined"
                            value={typebotUrl}
                            onChange={(e) => setTypebotUrl(e.target.value)}
                          />
                          <TextField
                            label="Nome do Bot"
                            variant="outlined"
                            value={typebotName}
                            onChange={(e) => setTypebotName(e.target.value)}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isTypebotEnabled === "true"}
                                onChange={() => setIsTypebotEnabled(isTypebotEnabled === "true" ? "false" : "true")}
                              />
                            }
                            label="Ativar Typebot"
                          />
                        </div>

                        <Divider className={classes.divider} />

                        {/* Seção: Configurações do Webhook/n8n */}
                        <h4 className={classes.sectionHeading}>Campanha Via Webhook/n8n</h4>
                        <TextField
                          label="URL do Webhook/n8n"
                          variant="outlined"
                          value={webhookUrl}
                          onChange={(e) => setWebhookUrl(e.target.value)}
                        />
                        <div className={classes.inputRow}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isWebhookEnabled === "true"}
                                onChange={() => setIsWebhookEnabled(isWebhookEnabled === "true" ? "false" : "true")}
                              />
                            }
                            label="Ativar Webhook"
                          />
                        </div>

                        <Divider className={classes.divider} />
                        <h4 className={classes.sectionHeading}>Campanha Local</h4>

                        {/* Seção: Automação Local */}
                        <TextField
                          label="Mensagem Recorrente"
                          variant="outlined"
                          multiline
                          rows={4}
                          value={recurrentMessage}
                          onChange={(e) => setRecurrentMessage(e.target.value)}
                        />
                        <div className={classes.inputRow}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isRecurrentMessageEnabled === "true"}
                                onChange={() => setIsRecurrentMessageEnabled(isRecurrentMessageEnabled === "true" ? "false" : "true")}
                              />
                            }
                            label="Ativar Mensagem Recorrente"
                          />
                         
                        </div>
                        <InputLabel>Repetir Durante</InputLabel>
                          <Select
                            value={recurrentMessageDays}
                            onChange={(e) => setRecurrentMessageDays(e.target.value)}
                          >
                            {[...Array(30).keys()].map((days) => (
                              <MenuItem key={days + 1} value={(days + 1).toString()}>{days + 1} dias</MenuItem>
                            ))}
                          </Select>

                        {/* Botão de Salvar dentro do loop de tags */}
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={handleSave}
                        >
                          Salvar
                        </Button>
                      </div>
                    </List>
                  </Collapse>
                </div>
              ))}
            </List>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

BoardSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BoardSettingsModal;
