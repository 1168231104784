import React, { useEffect, useState, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Alert from '@mui/material/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import api from "../../services/api";
import { head } from "lodash";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import useSettings from "../../hooks/useSettings";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
  background: "#f2f5f3",
  borderRadius: 4,
  width: "100%",
    "& .MuiTab-wrapper": {
      color: "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  linearProgress: {
    backgroundColor: "#008000"
  },
  selectSwitch: {
    marginTop: -20
  },
  configDiv: {
    padding: 0,
    marginBottom: 5,
  },
  configTitleH2: {
    padding: 0,
    margin: 0,
  },
  configP: {
    padding: 0,
    margin: 0,
  },
}));

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [smtpauthType, setUrlSmtpauthType] = useState("");
  const [loadingUrlSmtpauthType, setLoadingUrlSmtpauthType] = useState(false);
  const [usersmtpauthType, setUserSmtpauthType] = useState("");
  const [loadingSmtpauthType, setLoadingSmptauthType] = useState(false);
  const [clientsecretsmtpauthType, setClientSecrectSmtpauthType] = useState("");
  const [loadingClientSecrectSmtpauthType, setLoadingClientSecrectSmtpauthType] = useState(false);

  const [ipmkauthType, setIpMkauthType] = useState("");
  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] = useState(false);
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] = useState(false);

  const [urlTypeBot, setUrlTypeBot] = useState("");
  const [loadingUrlTypeBot, setLoadingUrlTypeBot] = useState(false);
  const [viewerTypeBot, setViewerTypeBot] = useState("");
  const [loadingViewerTypeBot, setLoadingViewerTypeBot] = useState(false);
  const [tokenTypebot, setTokenTypebot] = useState("");
  const [loadingTokenTypebot, setLoadingTokenTypebot] = useState(false);

  const [urlN8N, setUrlN8N] = useState("");
  const [loadingUrlN8N, setLoadingUrlN8N] = useState(false);

  const [asaasType, setAsaasType] = useState("");
  const [loadingAsaasType, setLoadingAsaasType] = useState(false);

  const { update } = useSettings();

  const isSuper = () => {
    return user.super;
  };

  const [showTypebot, setShowTypebot] = useState(false);
  const [showN8n, setShowN8n] = useState(false);
  const [showMktAuth, setShowMktAuth] = useState(false);
  const [showAsaas, setShowAsaas] = useState(false);
  const [showSmtpAuth, setShowSmtpAuth] = useState(false);

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {

      // MKT-AUTH
      const ipmkauthType = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthType) {
        setIpMkauthType(ipmkauthType.value);
      }

      const clientidmkauthType = settings.find((s) => s.key === "clientidmkauth");
      if (clientidmkauthType) {
        setClientIdMkauthType(clientidmkauthType.value);
      }

      const clientsecretmkauthType = settings.find((s) => s.key === "clientsecretmkauth");
      if (clientsecretmkauthType) {
        setClientSecrectMkauthType(clientsecretmkauthType.value);
      }

      //N8N
      const urlN8N = settings.find((s) => s.key === "n8nUrl");
      if (urlN8N) {
        setUrlN8N(urlN8N.value);
      }

      // TypeBot
      const urlTypeBot = settings.find((s) => s.key === "urlTypeBot");
      if (urlTypeBot) {
        setUrlTypeBot(urlTypeBot.value);
      }

      const viewerTypeBot = settings.find((s) => s.key === "viewerTypeBot");
      if (viewerTypeBot) {
        setViewerTypeBot(viewerTypeBot.value);
      }

      const tokenTypebot = settings.find((s) => s.key === "apiKeyTypeBot");
      if (tokenTypebot) {
        setTokenTypebot(tokenTypebot.value);
      }

      // SMTP-AUTH
      const smtpauthType = settings.find((s) => s.key === "smtpauth");
      if (smtpauthType) {
        setUrlSmtpauthType(smtpauthType.value);
      }

      const usersmtpauthType = settings.find((s) => s.key === "usersmtpauth");
      if (usersmtpauthType) {
        setUserSmtpauthType(usersmtpauthType.value);
      }

      const clientsecretsmtpauthType = settings.find((s) => s.key === "clientsecretsmtpauth");
      if (clientsecretsmtpauthType) {
        setClientSecrectSmtpauthType(clientsecretsmtpauthType.value);
      }

      // ASAAS
      const asaasType = settings.find((s) => s.key === "asaas");
      if (asaasType) {
        setAsaasType(asaasType.value);
      }

      // Show TypeBot
      const fetchShowTypebot = async () => {
        try {
          const showTypebotSett = await api.get("/settings/showTypebot/1");
          const showTypebotSettValue = showTypebotSett.data.value
          let typebotBool = false;

          if (showTypebotSett) {
            if (showTypebotSettValue === "true") {
              typebotBool = true
            } else {
              typebotBool = false
            }
            setShowTypebot(typebotBool);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchShowTypebot();

      // Show N8N
      const fetchShowN8n = async () => {
        try {
          const showN8nSett = await api.get("/settings/showN8n/1");
          const showN8nSettValue = showN8nSett.data.value
          let n8nBool = false;

          if (showN8nSett) {
            if (showN8nSettValue === "true") {
              n8nBool = true
            } else {
              n8nBool = false
            }
            setShowN8n(n8nBool);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchShowN8n();

      // Show MKT-AUTH
      const fetchShowMktAuth = async () => {
        try {
          const showMktAuthSett = await api.get("/settings/showMktAuth/1");
          const showMktAuthSettValue = showMktAuthSett.data.value
          let mktAuthBool = false;

          if (showMktAuthSett) {
            if (showMktAuthSettValue === "true") {
              mktAuthBool = true
            } else {
              mktAuthBool = false
            }
            setShowMktAuth(mktAuthBool);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchShowMktAuth();

      // Show ASAAS
      const fetchShowAsaas = async () => {
        try {
          const showAsaasSett = await api.get("/settings/showAsaas/1");
          const showAsaasSettValue = showAsaasSett.data.value
          let asaasBool = false;

          if (showAsaasSett) {
            if (showAsaasSettValue === "true") {
              asaasBool = true
            } else {
              asaasBool = false
            }
            setShowAsaas(asaasBool);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchShowAsaas();

      // Show SMTP-AUTH
      const fetchShowSmtpAuth = async () => {
        try {
          const showSmtpAuthSett = await api.get("/settings/showSmtpAuth/1");
          const showSmtpAuthSettValue = showSmtpAuthSett.data.value
          let smtpAuthBool = false;

          if (showSmtpAuthSett) {
            if (showSmtpAuthSettValue === "true") {
              smtpAuthBool = true
            } else {
              smtpAuthBool = false
            }
            setShowSmtpAuth(smtpAuthBool);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchShowSmtpAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  // N8N
  async function handleChangeUrlN8N(value) {
    setUrlN8N(value);
    setLoadingUrlN8N(true);
    await update({
      key: "n8nUrl",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingUrlN8N(false);
  }

  // TypeBot
  async function handleChangeUrlTypebot(value) {
    setUrlTypeBot(value);
    setLoadingUrlTypeBot(true);
    await update({
      key: "urlTypeBot",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingUrlTypeBot(false);
  }

  async function handleChangeViewerTypebot(value) {
    setViewerTypeBot(value);
    setLoadingViewerTypeBot(true);
    await update({
      key: "viewerTypeBot",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingViewerTypeBot(false);
  }

  async function handleChangeTokenTypebot(value) {
    setTokenTypebot(value);
    setLoadingTokenTypebot(true);
    await update({
      key: "apiKeyTypeBot",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingTokenTypebot(false);
  }
  
  // MKT-AUTH
  async function handleChangeIpMkauth(value) {
    setIpMkauthType(value);
    setLoadingIpMkauthType(true);
    await update({
      key: "ipmkauth",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingIpMkauthType(false);
  }
  
  async function handleChangeClientIdMkauth(value) {
    setClientIdMkauthType(value);
    setLoadingClientIdMkauthType(true);
    await update({
      key: "clientidmkauth",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingClientIdMkauthType(false);
  }

  async function handleChangeClientSecrectMkauth(value) {
    setClientSecrectMkauthType(value);
    setLoadingClientSecrectMkauthType(true);
    await update({
      key: "clientsecretmkauth",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingClientSecrectMkauthType(false);
  }

  // SMTP-AUTH
  async function handleChangeUrlSmtpauth(value) {
    setUrlSmtpauthType(value);
    setLoadingUrlSmtpauthType(true);
    await update({
      key: "smtpauth",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingUrlSmtpauthType(false);
  }

  async function handleChangeUserSmptauth(value) {
    setUserSmtpauthType(value);
    setLoadingSmptauthType(true);
    await update({
      key: "usersmtpauth",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingSmptauthType(false);
  }

  async function handleChangeClientSecrectSmtpauth(value) {
    setClientSecrectSmtpauthType(value);
    setLoadingClientSecrectSmtpauthType(true);
    await update({
      key: "clientsecretsmtpauth",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingClientSecrectSmtpauthType(false);
  }

  // ASAAS
  async function handleChangeAsaas(value) {
    setAsaasType(value);
    setLoadingAsaasType(true);
    await update({
      key: "asaas",
      value,
    });
    toast.success(i18n.t("Saved successfully"));
    setLoadingAsaasType(false);
  }

  // Switch Show Typebot Handler
  async function switcShowTypebotHandler(event) {
    const value = event.target.checked
    setShowTypebot(value);

    await update({
      key: "showTypebot",
      value,
    });

    if (value === true) {
      toast.success(i18n.t("Successfully enabled"));
    } else {
      toast.success(i18n.t("Successfully disabled"));
    }
  };

  // Switch N8N Handler
  async function switchShowN8nHandler(event) {
    const value = event.target.checked
    setShowN8n(value);

    await update({
      key: "showN8n",
      value,
    });

    if (value === true) {
      toast.success(i18n.t("Successfully enabled"));
    } else {
      toast.success(i18n.t("Successfully disabled"));
    }
  }

  // Switch MKT Auth Handler
  async function switchShowMktAuthHandler(event) {
    const value = event.target.checked
    setShowMktAuth(value);

    await update({
      key: "showMktAuth",
      value,
    });

    if (value === true) {
      toast.success(i18n.t("Successfully enabled"));
    } else {
      toast.success(i18n.t("Successfully disabled"));
    }
  }

  // Switch ASAAS Handler
  async function switchShowAsaasHandler(event) {
    const value = event.target.checked
    setShowAsaas(value);

    await update({
      key: "showAsaas",
      value,
    });

    if (value === true) {
      toast.success(i18n.t("Successfully enabled"));
    } else {
      toast.success(i18n.t("Successfully disabled"));
    }
  }

  // Switch SMTP-AUTH Handler
  async function switchShowSmtpAuthHandler(event) {
    const value = event.target.checked
    setShowSmtpAuth(value);

    await update({
      key: "showSmtpAuth",
      value,
    });

    if (value === true) {
      toast.success(i18n.t("Successfully enabled"));
    } else {
      toast.success(i18n.t("Successfully disabled"));
    }
  }

  return (
    <>
      {/* TypeBot */}
      {showTypebot ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>TypeBot</h2>
      </div> 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="typeBotUrl"
              name="typeBot Url"
              margin="dense"
              label="TypeBot Viewer Url"
              variant="outlined"
              value={urlTypeBot}
              onChange={async (e) => {
                handleChangeUrlTypebot(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingUrlTypeBot && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="typeBot Viewer"
              name="typeBot Viewer"
              margin="dense"
              label={i18n.t("Public Id TypeBot - Bot Name")}
              variant="outlined"
              value={viewerTypeBot}
              onChange={async (e) => {
                handleChangeViewerTypebot(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingViewerTypeBot && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="typeBot Token"
              name="typeBot Token"
              margin="dense"
              label={i18n.t("settings.options.wordRestartFlow")}
              variant="outlined"
              value={tokenTypebot}
              onChange={async (e) => {
                handleChangeTokenTypebot(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingTokenTypebot && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <div className={classes.configDiv}>
              <h2 className={classes.configTitleH2}>TypeBot</h2>
            </div>
            <Alert severity="warning">{i18n.t("This integration is disabled")}</Alert>
          </FormControl>
        </Grid>
       </Grid>
      }

      {isSuper() ?
      <Grid spacing={3} container style={{ marginTop: 8, marginBottom: 0 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectSwitch}>
            <FormControlLabel
              label={i18n.t("Enabled")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={showTypebot}
                  onChange={switcShowTypebotHandler}
                  name="showTypebot"
                  color="primary"
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      : null}

      {/* N8N */}
      {showN8n ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>N8N</h2>
      </div>
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="n8nUrl"
              name="n8n Url"
              margin="dense"
              label="N8N/WebHook Url"
              variant="outlined"
              value={urlN8N}
              onChange={async (e) => {
                handleChangeUrlN8N(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingUrlN8N && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <div className={classes.configDiv}>
              <h2 className={classes.configTitleH2}>N8N</h2>
            </div>
            <Alert severity="warning">{i18n.t("This integration is disabled")}</Alert>
          </FormControl>
        </Grid>
       </Grid>
      }

      {isSuper() ?
      <Grid spacing={3} container style={{ marginTop: 8, marginBottom: 0 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectSwitch}>
            <FormControlLabel
              label={i18n.t("Enabled")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={showN8n}
                  onChange={switchShowN8nHandler}
                  name="showN8n"
                  color="primary"
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      : null}

      {/* MKT-AUTH */}
      {showMktAuth ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>MKT-AUTH</h2>
      </div>
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="ipmkauth"
              name="ipmkauth"
              margin="dense"
              label="Ip Mk-Auth"
              variant="outlined"
              value={ipmkauthType}
              onChange={async (e) => {
                handleChangeIpMkauth(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingIpMkauthType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="clientidmkauth"
              name="clientidmkauth"
              margin="dense"
              label="Client Id"
              variant="outlined"
              value={clientidmkauthType}
              onChange={async (e) => {
                handleChangeClientIdMkauth(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingClientIdMkauthType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="clientsecretmkauth"
              name="clientsecretmkauth"
              margin="dense"
              label="Client Secret"
              variant="outlined"
              value={clientsecretmkauthType}
              onChange={async (e) => {
                handleChangeClientSecrectMkauth(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingClientSecrectMkauthType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <div className={classes.configDiv}>
              <h2 className={classes.configTitleH2}>MKT-AUTH</h2>
            </div>
            <Alert severity="warning">{i18n.t("This integration is disabled")}</Alert>
          </FormControl>
        </Grid>
       </Grid>
      }

      {isSuper() ?
      <Grid spacing={3} container style={{ marginTop: 8, marginBottom: 0 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectSwitch}>
            <FormControlLabel
              label={i18n.t("Enabled")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={showMktAuth}
                  onChange={switchShowMktAuthHandler}
                  name="showMktAuth"
                  color="primary"
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      : null}

      {/* ASAAS */}
      {showAsaas ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>ASAAS</h2>
      </div>
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="asaas"
              name="asaas"
              margin="dense"
              label="Token Asaas"
              variant="outlined"
              value={asaasType}
              onChange={async (e) => {
                handleChangeAsaas(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingAsaasType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <div className={classes.configDiv}>
              <h2 className={classes.configTitleH2}>ASAAS</h2>
            </div>
            <Alert severity="warning">{i18n.t("This integration is disabled")}</Alert>
          </FormControl>
        </Grid>
       </Grid>
      }

      {isSuper() ?
      <Grid spacing={3} container style={{ marginTop: 8, marginBottom: 0 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectSwitch}>
            <FormControlLabel
              label={i18n.t("Enabled")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={showAsaas}
                  onChange={switchShowAsaasHandler}
                  name="showAsaas"
                  color="primary"
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      : null}

      {/* SMTP-AUTH */}
      {showSmtpAuth ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>SMTP-AUTH</h2>
      </div>
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="smtpauth"
              name="smtpauth"
              margin="dense"
              label="Url SMTP"
              variant="outlined"
              value={smtpauthType}
              onChange={async (e) => {
                handleChangeUrlSmtpauth(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingUrlSmtpauthType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="usersmtpauth"
              name="usersmtpauth"
              margin="dense"
              label="User Smpt"
              variant="outlined"
              value={usersmtpauthType}
              onChange={async (e) => {
                handleChangeUserSmptauth(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingSmtpauthType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="clientsecretsmtpauth"
              name="clientsecretsmtpauth"
              margin="dense"
              label="PassWord Smpt"
              variant="outlined"
              value={clientsecretsmtpauthType}
              onChange={async (e) => {
                handleChangeClientSecrectSmtpauth(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingClientSecrectSmtpauthType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectContainer}>
            <div className={classes.configDiv}>
              <h2 className={classes.configTitleH2}>SMTP-AUTH</h2>
            </div>
            <Alert severity="warning">{i18n.t("This integration is disabled")}</Alert>
          </FormControl>
        </Grid>
       </Grid>
      }

      {isSuper() ?
      <Grid spacing={3} container style={{ marginTop: 8, marginBottom: 0 }}>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl className={classes.selectSwitch}>
            <FormControlLabel
              label={i18n.t("Enabled")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={showSmtpAuth}
                  onChange={switchShowSmtpAuthHandler}
                  name="showSmtpAuth"
                  color="primary"
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      : null}
    </>
  );
}
