import React, { useEffect, useState, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import useSettings from "../../hooks/useSettings";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
    tab: {
    background: "#f2f5f3",
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  linearProgress: {
    backgroundColor: "#008000"
  },
}));

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [scheduleType, setScheduleType] = useState("disabled");
  const [chatBotType, setChatBotType] = useState("text");

  const [loadingScheduleType, setLoadingScheduleType] = useState(false);

  const [UserCreation, setUserCreation] = useState("disabled");
  const [loadingUserCreation, setLoadingUserCreation] = useState(false);

  const [CheckMsgIsGroup, setCheckMsgIsGroup] = useState("enabled");
  const [loadingCheckMsgIsGroup, setLoadingCheckMsgIsGroup] = useState(false);

  const [SendGreetingAccepted, setSendGreetingAccepted] = useState("enabled");
  const [loadingSendGreetingAccepted, setLoadingSendGreetingAccepted] = useState(false);

  const [UserRandom, setUserRandom] = useState("enabled");
  const [loadingUserRandom, setLoadingUserRandom] = useState(false);

  const [SettingsTransfTicket, setSettingsTransfTicket] = useState("enabled");
  const [loadingSettingsTransfTicket, setLoadingSettingsTransfTicket] = useState(false);

  const [AcceptCallWhatsapp, setAcceptCallWhatsapp] = useState("enabled");
  const [loadingAcceptCallWhatsapp, setLoadingAcceptCallWhatsapp] = useState(false);

  const [sendSignMessage, setSendSignMessage] = useState("enabled");
  const [loadingSendSignMessage, setLoadingSendSignMessage] = useState(false);

  const [sendGreetingMessageOneQueues, setSendGreetingMessageOneQueues] = useState("enabled");
  const [loadingSendGreetingMessageOneQueues, setLoadingSendGreetingMessageOneQueues] = useState(false);

  const [sendQueuePosition, setSendQueuePosition] = useState("enabled");
  const [loadingSendQueuePosition, setLoadingSendQueuePosition] = useState(false);

  const [sendFarewellWaitingTicket, setSendFarewellWaitingTicket] = useState("enabled");
  const [loadingSendFarewellWaitingTicket, setLoadingSendFarewellWaitingTicket] = useState(false);

  const [acceptAudioMessageContact, setAcceptAudioMessageContact] = useState("enabled");
  const [loadingAcceptAudioMessageContact, setLoadingAcceptAudioMessageContact] = useState(false);

  const [showAllMessagesUserProfile, setShowAllMessagesUserProfile] = useState("enabled");
  const [loadingShowAllMessagesUserProfile, setLoadingShowAllMessagesUserProfile] = useState(false);

  const [showSignupLink, setShowSignupLink] = useState("enabled");
  const [loadingShowSignupLink, setLoadingShowSignupLink] = useState(false);
  
  const { update } = useSettings();
  const isSuper = () => {
    return user.super;
  };

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userCreation = settings.find((s) => s.key === "userCreation");
      if (userCreation) {
        setUserCreation(userCreation.value);
      }

      const scheduleType = settings.find((s) => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }

      const chatBotType = settings.find((s) => s.key === "chatBotType");
      if (chatBotType) {
        setChatBotType(chatBotType.value);
      }

      const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroup(CheckMsgIsGroup.value);
      }

      const SendGreetingAccepted = settings.find((s) => s.key === "sendGreetingAccepted");
      if (SendGreetingAccepted) {
        setSendGreetingAccepted(SendGreetingAccepted.value);
      }

      const UserRandom = settings.find((s) => s.key === "userRandom");
      if (UserRandom) {
        setUserRandom(UserRandom.value);
      }

      const SettingsTransfTicket = settings.find((s) => s.key === "sendMsgTransfTicket");
      if (SettingsTransfTicket) {
        setSettingsTransfTicket(SettingsTransfTicket.value);
      }

      const AcceptCallWhatsapp = settings.find((s) => s.key === "acceptCallWhatsapp");
      if (AcceptCallWhatsapp) {
        setAcceptCallWhatsapp(AcceptCallWhatsapp.value);
      }

      const sendSignMessage = settings.find((s) => s.key === "sendSignMessage");
      if (sendSignMessage) {
        setSendSignMessage(sendSignMessage.value)
      }

      const sendGreetingMessageOneQueues = settings.find((s) => s.key === "sendGreetingMessageOneQueues");
      if (sendGreetingMessageOneQueues) {
        setSendGreetingMessageOneQueues(sendGreetingMessageOneQueues.value)
      }

      const sendQueuePosition = settings.find((s) => s.key === "sendQueuePosition");
      if (sendQueuePosition) {
        setSendQueuePosition(sendQueuePosition.value)
      }

      const sendFarewellWaitingTicket = settings.find((s) => s.key === "sendFarewellWaitingTicket");
      if (sendFarewellWaitingTicket) {
        setSendFarewellWaitingTicket(sendFarewellWaitingTicket.value)
      }

      const acceptAudioMessageContact = settings.find((s) => s.key === "acceptAudioMessageContact");
      if (acceptAudioMessageContact) {
        setAcceptAudioMessageContact(acceptAudioMessageContact.value)
      }

      const showAllMessagesUserProfile = settings.find((s) => s.key === "showAllMessagesUserProfile");
      if (showAllMessagesUserProfile) {
        setShowAllMessagesUserProfile(showAllMessagesUserProfile.value)
      }

      // Show Signup Link
      const fetchShowSignupLink = async () => {
        try {
          const showSignupLinkSett = await api.get("/settings/showSignupLink/1");
          const showSignupLinkSettValue = showSignupLinkSett.data.value
          let SignupLinkBool = false;
          
          if (showSignupLinkSett) {
            if (showSignupLinkSettValue === "enabled") {
              SignupLinkBool = "enabled"
            } else {
              SignupLinkBool = "disabled"
            }
            setShowSignupLink(SignupLinkBool);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchShowSignupLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  async function handleChangeUserCreation(value) {
    setUserCreation(value);
    setLoadingUserCreation(true);
    await update({
      key: "userCreation",
      value,
    });
    setLoadingUserCreation(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({
      key: "scheduleType",
      value,
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleChatBotType(value) {
    setChatBotType(value);
    await update({
      key: "chatBotType",
      value,
    });
    if (typeof scheduleTypeChanged === "function") {
      setChatBotType(value);
    }
  }

  async function handleCheckMsgIsGroup(value) {
    setCheckMsgIsGroup(value);
    setLoadingCheckMsgIsGroup(true);
    await update({
      key: "CheckMsgIsGroup",
      value,
    });
    setLoadingCheckMsgIsGroup(false);
  }

  async function handleSendGreetingAccepted(value) {
    setSendGreetingAccepted(value);
    setLoadingSendGreetingAccepted(true);
    await update({
      key: "sendGreetingAccepted",
      value,
    });
    setLoadingSendGreetingAccepted(false);
  }

  async function handleUserRandom(value) {
    setUserRandom(value);
    setLoadingUserRandom(true);
    await update({
      key: "userRandom",
      value,
    });
    setLoadingUserRandom(false);
  }

  async function handleSettingsTransfTicket(value) {
    setSettingsTransfTicket(value);
    setLoadingSettingsTransfTicket(true);
    await update({
      key: "sendMsgTransfTicket",
      value,
    });
    setLoadingSettingsTransfTicket(false);
  }

  async function handleAcceptCallWhatsapp(value) {
    setAcceptCallWhatsapp(value);
    setLoadingAcceptCallWhatsapp(true);
    await update({
      key: "acceptCallWhatsapp",
      value,
    });
    setLoadingAcceptCallWhatsapp(false);
  }

  async function handleSendSignMessage(value) {
    setSendSignMessage(value);
    setLoadingSendSignMessage(true);
    await update({
      key: "sendSignMessage",
      value,
    });
    setLoadingSendSignMessage(false);
  }

  async function handleSendGreetingMessageOneQueues(value) {
    setSendGreetingMessageOneQueues(value);
    setLoadingSendGreetingMessageOneQueues(true);
    await update({
      key: "sendGreetingMessageOneQueues",
      value,
    });
    setLoadingSendGreetingMessageOneQueues(false);
  }

  async function handleSendQueuePosition(value) {
    setSendQueuePosition(value);
    setLoadingSendQueuePosition(true);
    await update({
      key: "sendQueuePosition",
      value,
    });
    setLoadingSendQueuePosition(false);
  }

  async function handleSendFarewellWaitingTicket(value) {
    setSendFarewellWaitingTicket(value);
    setLoadingSendFarewellWaitingTicket(true);
    await update({
      key: "sendFarewellWaitingTicket",
      value,
    });
    setLoadingSendFarewellWaitingTicket(false);
  }

  async function handleAcceptAudioMessageContact(value) {
    setAcceptAudioMessageContact(value);
    setLoadingAcceptAudioMessageContact(true);
    await update({
      key: "acceptAudioMessageContact",
      value,
    });
    setLoadingAcceptAudioMessageContact(false);
  }

  async function handleShowAllMessagesUserProfile(value) {
    setShowAllMessagesUserProfile(value);
    setLoadingShowAllMessagesUserProfile(true);
    await update({
      key: "showAllMessagesUserProfile",
      value,
    });
    setLoadingShowAllMessagesUserProfile(false);
  }

  async function handleShowSignupLink(value) {
    setShowSignupLink(value);
    setLoadingShowSignupLink(true);
    await update({
      key: "showSignupLink",
      value,
    });
    setLoadingShowSignupLink(false);
  }

  return (
    <>
      <Grid spacing={3} container>
        {/* CRIAÇÃO DE COMPANY/USERS */}
        {isSuper() ?
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="UserCreation-label">{i18n.t("Add Company/User")}</InputLabel>
            <Select
              labelId="UserCreation-label"
              value={UserCreation}
              onChange={async (e) => {
                handleChangeUserCreation(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("Disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("Enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserCreation && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        : null}

        {/* AGENDAMENTO DE EXPEDIENTE */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="schedule-type-label">{i18n.t("settings.options.officeScheduling")}</InputLabel>
            <Select
              labelId="schedule-type-label"
              value={scheduleType}
              onChange={async (e) => {
                handleScheduleType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"queue"}>{i18n.t("settings.options.queueSectorManagement")}</MenuItem>
              <MenuItem value={"company"}>{i18n.t("settings.options.managementByCompany")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ENVIAR SAUDAÇÃO AO ACEITAR O TICKET */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendGreetingAccepted-label">{i18n.t("settings.options.sendGreetingAcceptingService")}</InputLabel>
            <Select
              labelId="sendGreetingAccepted-label"
              value={SendGreetingAccepted}
              onChange={async (e) => {
                handleSendGreetingAccepted(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendGreetingAccepted && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ESCOLHER OPERADOR ALEATORIO */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="userRandom-label">{i18n.t("settings.options.chooseRandomOperatorWhenChoosingSector")}</InputLabel>
            <Select
              labelId="userRandom-label"
              value={UserRandom}
              onChange={async (e) => {
                handleUserRandom(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRandom && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ENVIAR MENSAGEM DE TRANSFERENCIA DE SETOR/ATENDENTE */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendMsgTransfTicket-label">{i18n.t("settings.options.sendSectorAttendantTransferMessage")}</InputLabel>
            <Select
              labelId="sendMsgTransfTicket-label"
              value={SettingsTransfTicket}
              onChange={async (e) => {
                handleSettingsTransfTicket(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSettingsTransfTicket && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* IGNORAR MENSAGEM DE GRUPOS */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="CheckMsgIsGroup-label">{i18n.t("settings.options.ignoreGroupMessages")}</InputLabel>
            <Select
              labelId="CheckMsgIsGroup-label"
              value={CheckMsgIsGroup}
              onChange={async (e) => {
                handleCheckMsgIsGroup(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingCheckMsgIsGroup && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* TIPO DO BOT */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="schedule-type-label">{i18n.t("settings.options.botType")}</InputLabel>
            <Select
              labelId="schedule-type-label"
              value={chatBotType}
              onChange={async (e) => {
                handleChatBotType(e.target.value);
              }}
            >
              <MenuItem value={"text"}>{i18n.t("commom.text")}</MenuItem>
              {/*<MenuItem value={"button"}>Botões</MenuItem>
              <MenuItem value={"list"}>Lista</MenuItem>*/}
              <MenuItem value={"typeBot"}>typeBot</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* AVISO SOBRE LIGAÇÃO DO WHATSAPP */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="acceptCallWhatsapp-label">{i18n.t("settings.options.notAcceptCallsOnWhatsApp")}</InputLabel>
            <Select
              labelId="acceptCallWhatsapp-label"
              value={AcceptCallWhatsapp}
              onChange={async (e) => {
                handleAcceptCallWhatsapp(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingAcceptCallWhatsapp && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* HABILITAR PARA O ATENDENTE RETIRAR O ASSINATURA */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendSignMessage-label">{i18n.t("settings.options.allowsAttendantChooseSendSignature")}</InputLabel>
            <Select
              labelId="sendSignMessage-label"
              value={sendSignMessage}
              onChange={async (e) => {
                handleSendSignMessage(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendSignMessage && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ENVIAR SAUDAÇÃO QUANDO HOUVER SOMENTE 1 FILA */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendGreetingMessageOneQueues-label">{i18n.t("settings.options.sendGreetingWhenOneSector")}</InputLabel>
            <Select
              labelId="sendGreetingMessageOneQueues-label"
              value={sendGreetingMessageOneQueues}
              onChange={async (e) => {
                handleSendGreetingMessageOneQueues(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendGreetingMessageOneQueues && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ENVIAR MENSAGEM COM A POSIÇÃO DA FILA */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendQueuePosition-label">{i18n.t("settings.options.sendMessageWithQueuePosition")}</InputLabel>
            <Select
              labelId="sendQueuePosition-label"
              value={sendQueuePosition}
              onChange={async (e) => {
                handleSendQueuePosition(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendQueuePosition && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* ENVIAR MENSAGEM DE DESPEDIDA NO AGUARDANDO */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="sendFarewellWaitingTicket-label">{i18n.t("settings.options.sendFarewellMessageWhenWaiting")}</InputLabel>
            <Select
              labelId="sendFarewellWaitingTicket-label"
              value={sendFarewellWaitingTicket}
              onChange={async (e) => {
                handleSendFarewellWaitingTicket(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendFarewellWaitingTicket && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="acceptAudioMessageContact-label">{i18n.t("settings.options.agreeReceiveAudioFromAllContacts")}</InputLabel>
            <Select
              labelId="acceptAudioMessageContact-label"
              value={acceptAudioMessageContact}
              onChange={async (e) => {
                handleAcceptAudioMessageContact(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>{i18n.t("settings.options.disabled")}</MenuItem>
              <MenuItem value={"enabled"}>{i18n.t("settings.options.enabled")}</MenuItem>
            </Select>
            <FormHelperText>
              {loadingAcceptAudioMessageContact && i18n.t("Updating") + "..."}
            </FormHelperText>
          </FormControl>
          </Grid>

          {/* SHOW ALL MESSAGES USER PROFILE */}
          {isSuper() ?
          <Grid xs={12} sm={6} md={4} item>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="showAllMessagesUserProfile-label">{i18n.t("Shows all Messages for the User Profile")}</InputLabel>
              <Select
                labelId="showAllMessagesUserProfile-label"
                value={showAllMessagesUserProfile}
                onChange={async (e) => {
                  handleShowAllMessagesUserProfile(e.target.value);
                }}
              >
                <MenuItem value={"disabled"}>{i18n.t("Disabled")}</MenuItem>
                <MenuItem value={"enabled"}>{i18n.t("Enabled")}</MenuItem>
              </Select>
              <FormHelperText>
                {loadingShowAllMessagesUserProfile && i18n.t("Updating") + "..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          : null}

          {/* SHOW SIGNUP LINK */}
          {isSuper() ?
          <Grid xs={12} sm={6} md={4} item>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="showSignupLink-label">{i18n.t("Shows Signup Link on Login/Reset Password Page")}</InputLabel>
              <Select
                labelId="showSignupLink-label"
                value={showSignupLink}
                onChange={async (e) => {
                  handleShowSignupLink(e.target.value);
                }}
              >
                <MenuItem value={"disabled"}>{i18n.t("Disabled")}</MenuItem>
                <MenuItem value={"enabled"}>{i18n.t("Enabled")}</MenuItem>
              </Select>
              <FormHelperText>
                {loadingShowSignupLink && i18n.t("Updating") + "..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          : null}
      </Grid>      
    </>
  );
}
