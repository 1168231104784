import React, { useEffect, useState, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Stack from '@mui/material/Stack';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import api from "../../services/api";
import { head } from "lodash";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import useSettings from "../../hooks/useSettings";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
  background: "#f2f5f3",
  borderRadius: 4,
  width: "100%",
    "& .MuiTab-wrapper": {
      color: "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  linearProgress: {
    backgroundColor: "#008000"
  },
  selectSwitch: {
    marginTop: -20
  },
  configDiv: {
    padding: 0,
    marginBottom: 5,
  },
  configTitleH2: {
    padding: 0,
    margin: 0,
  },
  configP: {
    padding: 0,
    margin: 0,
  },
}));

export default function Design(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const { update } = useSettings();

  const isSuper = () => {
    return user.super;
  };

  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [bgUploadProgress, setBgUploadProgress] = React.useState(0);
  const attachmentFile = useRef(null);
  const attachmentBgFile = useRef(null);

  const [logo, setLogo] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  
  const MAX_UPLOAD_SIZE = 1 * 1024 * 1024; // 2MB in bytes

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      // Logo
      const fetchLogo = async () => {
        try {
          const logoSett = await api.get("/settings/logo/1");
          const logoSettValue = logoSett.data.value

          if (logoSett) {
            setLogo(logoSettValue);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchLogo();

      // Background Image
      const fetchBackgroundImage = async () => {
        try {
          const bgSett = await api.get("/settings/bgImage/1");
          const bgSettValue = bgSett.data.value

          if (bgSett) {
            setBackgroundImage(bgSettValue);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchBackgroundImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    const formData = new FormData();
    formData.append('file', file);
    var formFile = formData.get('file');
    const ext = formFile.name.split('.').pop()
    console.log(ext);

    // Only upload a JPG, JPEG or PNG file
    if( ext.toLowerCase() != "jpg" && ext.toLowerCase() != "jpeg" && ext.toLowerCase() != "png" ) {
      toast.error(i18n.t("Please upload a JPG, JPEG or PNG file"));

      return
    }

    // Only upload a file less than 1 MB
    if( formFile.size > MAX_UPLOAD_SIZE ) {
      toast.error(i18n.t("Please upload a file less than 1 MB"));

      return 
    }
    
    api.delete(`/media/logo/1`)
      .then(response => {
        // 
      })
      .catch(error => {
        console.error(error);
        // handle error here
      });

    api.post(`/media/logo/1`, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      }
    }).then(response => {
      setTimeout(function(){
        setUploadProgress(0)
      }, 5000);

      toast.success(i18n.t("Logo updated successfully!"));

      setLogo(response.data.filename)
    })
    .catch(error => {
      console.error(error);
      // handle error here
    });
  };

  const handleAttachmentBgFile = (e) => {
    const file = head(e.target.files);
    const formData = new FormData();
    formData.append('file', file);
    var formFile = formData.get('file');
    const ext = formFile.name.split('.').pop()

    // Only upload a JPG, JPEG or PNG file
    if( ext.toLowerCase() != "jpg" && ext.toLowerCase() != "jpeg" && ext.toLowerCase() != "png" ) {
      toast.error(i18n.t("Please upload a JPG, JPEG or PNG file"));

      return
    }

    // Only upload a file less than 1 MB
    if( formFile.size > MAX_UPLOAD_SIZE ) {
      toast.error(i18n.t("Please upload a file less than 1 MB"));

      return 
    }
    
    api.delete(`/media/bgImage/1`)
      .then(response => {
        // 
      })
      .catch(error => {
        console.error(error);
        // handle error here
      });

    api.post(`/media/bgImage/1`, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setBgUploadProgress(percentCompleted);
      }
    }).then(response => {
      setTimeout(function(){
        setBgUploadProgress(0)
      }, 5000);

      toast.success(i18n.t("Background image updated successfully!"));

      setBackgroundImage(response.data.filename)
    })
    .catch(error => {
      console.error(error);
      // handle error here
    });
  };

  return (
    <>
      {/* Logo */}
      {isSuper() ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>Logo</h2>
        <p>{i18n.t("The recommended image size is")} 334 x 84 pixels.</p>
      </div> 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={attachmentFile}
                onChange={(e) => handleAttachmentFile(e)}
              />
            </div>
            <Button
              mt={1}
              style={{
                backgroundColor: "green",
                color: "white",
                marginBottom: "10px"
              }}
              onClick={() => attachmentFile.current.click()}
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={20}>
              <LinearProgress className={classes.linearProgress} variant="determinate" value={uploadProgress} />
            </Stack>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
            {logo != null && (
              <img src={process.env.REACT_APP_BACKEND_URL + '/public/media/' + logo} className={classes.selectContainer} />
            )} 
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : null}

      {/* Logo */}
      {isSuper() ? 
      <>
      <div className={classes.configDiv}>
        <h2 className={classes.configTitleH2}>{i18n.t("Background Image on Login Page")}</h2>
        <p>{i18n.t("The recommended image size is")} 1920 × 1080 pixels.</p>
      </div> 
      <Grid spacing={3} container style={{ marginBottom: 8 }}>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={attachmentBgFile}
                onChange={(e) => handleAttachmentBgFile(e)}
              />
            </div>
            <Button
              mt={1}
              style={{
                backgroundColor: "green",
                color: "white",
                marginBottom: "10px"
              }}
              onClick={() => attachmentBgFile.current.click()}
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={20}>
              <LinearProgress className={classes.linearProgress} variant="determinate" value={bgUploadProgress} />
            </Stack>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
            {backgroundImage != null && (
              <img src={process.env.REACT_APP_BACKEND_URL + '/public/media/' + backgroundImage} className={classes.selectContainer} />
            )} 
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={3} item>
          <FormControl className={classes.selectContainer}>
          </FormControl>
        </Grid>
      </Grid>
      </>
      : null}
    </>
  );
}
