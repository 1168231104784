const messages = {
	es: {
		translations: {
			header: {
				greetings: {
					hello: "Hola",
					welcome: "Bienvenido a",
					activeUntil: "Activo hasta"
				},
			},

			dashboard: {
				title: {
				  inservice: "EN SERVICIO",
				  waiting: "ESPERANDO SERVICIO",
				  onlineAgents: "AGENTES EN LÍNEA",
				  completedTickets: "TICKETS COMPLETADOS",
				  totalSentMessages: "TOTAL DE MENSAJES ENVIADOS",
				  totalReceivedMessages: "TOTAL DE MENSAJES RECIBIDOS",
				  newLeads: "NUEVOS LEADS",
				  averageHandlingTime: "TIEMPO PROMEDIO DE ATENCIÓN",
				  averageWaitTime: "TIEMPO PROMEDIO DE ESPERA",
				  totalUserInteractions: "TOTAL DE INTERACCIONES POR USUARIO",
				  totalInteractions: "TOTAL DE INTERACCIONES",
				  startDate: "Fecha de Inicio",
				  endDate: "Fecha de Fin",
				  filter: "FILTRAR",
				  showFilterDate: "VER SERVICIOS POR PERIODO",
				  showFilterUser: "VER SERVICIOS POR USUARIO",
				  hideFilter: "OCULTAR FILTRO"
				}
			  },

			messageVariablesPicker: {
				label: "Variables Disponibles",
				vars: {
				  contactFirstName: "Primer Nombre",
				  contactName: "Nombre",
				  user: "Agente",
				  greeting: "Saludo",
				  protocolNumber: "Protocolo",
				  date: "Fecha",
				  hour: "Hora",
				  ticket_id: "Número de Ticket",
				  queue: "Departamento",
				  connection: "Conexión"
				}
			  },
			  
			  quickemessage: {
				toasts: {
				  success: "Atajo agregado exitosamente",
				  deleted: "Atajo eliminado exitosamente",
				},
				title: "Respuestas Rápidas",
				buttons: {
				  add: "Nueva Respuesta",
				},
				dialog: {
				  add: "Agregar",
				  edit: "Editar",
				  form: {
					shortcode: "Atajo",
					message: "Mensaje",
					global: "Global",
				  },
				  buttons: {
					cancel: "Cancelar",
					edit: "Guardar",
					attach: "Adjuntar",
					add: "Guardar",
				  },
				},
				confirmationModal: {
				  deleteTitle: "Eliminación",
				  deleteMessage: "¡Esta acción es irreversible! ¿Desea continuar?",
				},
			  },
			  
			  quickemessages: {
				toasts: {
				  success: "Atajo agregado exitosamente",
				  deleted: "Atajo eliminado exitosamente",
				},
				title: "Respuestas Rápidas",
				table: {
				  shortcode: "Atajo",
				  mediaName: "Archivo",
				  status: "Global",
				  actions: "Acción",
				},
				searchPlaceholder: "Buscar",
				buttons: {
				  add: "Agregar",
				  attach: "Adjuntar Archivo",
				  cancel: "Cancelar",
				  edit: "Guardar",
				},
				confirmationModal: {
				  deleteTitle: "Eliminación",
				  deleteMessage: "¡Esta acción es irreversible! ¿Desea continuar?",
				},
			  },
			  
			signup: {
				title: "Registrar",
				toasts: {
					success: "¡Usuario creado con éxito! Haz tu login!!!.",
					fail: "Error al crear usuario. Verifique los datos ingresados.",
				},
				form: {
					name: "Nonbre",
					email: "Email",
					password: "Contraseña",
					company: "Nombre de la empresa",
					phone: "Número Whatsapp"
				},
				buttons: {
					submit: "Registrar",
					login: "¿Ya tienes una cuenta? Inicia sesión!",
				},
			},
			login: {
				title: "Login",
				form: {
					email: "Email",
					password: "Contraseña",
				},
				buttons: {
					submit: "Login",
					register: "¿No tienes una cuenta? Regístrate!",
				},
			},
			forgotpassword: {
				toasts: {
				  success: "¡Email enviado con éxito!",
				  error: "¡El correo electrónico no encontrado!",
				},
				passwordRules: "Su contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una letra minúscula y un número.",
			},
			smtpCredentials: {
				toasts: {
					error: "Ocurrio un error. Credenciales SMTP.",
				},
			},
			auth: {
				toasts: {
					success: "Login exitoso!",
				},
				dueDate: {
					expiration: "Su suscripción expira en",
					days: "dias!",
					day: "dia!",
					expirationToday: "Su suscripción expira hoy!",
				},
				token: "Token",
			},
			companies: {
				title: "Registro de Empresas",
				form: {
					name: "Nombre de la empresa",
					plan: "Plan",
					token: "Token",
					submit: "Crear",
					success: "Empresa creada con éxito!",
				},
			},
			connections: {
				title: "Conexiones",
				toasts: {
					deleted: "Conexión eliminada con éxito!",
				},
				confirmationModal: {
					deleteTitle: "Borrar conexión",
					deleteMessage: "Usted está seguro? Esta acción no se puede deshacer.",
					disconnectTitle: "Desconectar WhatsApp",
					disconnectMessage: "Usted está seguro? Esta acción no se puede deshacer.",
				},
				buttons: {
					add: "Agregar WhatsApp",
					disconnect: "Desconectar",
					tryAgain: "Intentar de nuevo",
					qrcode: "QR CODE",
					newQr: "Nuevo QR CODE",
					connecting: "Conectando",
				},
				toolTips: {
					disconnected: {
						title: "Falló la conexión con el celular",
						content: "Verifique que su celular esté conectado a internet y que WhatsApp esté abierto",
					},
					qrcode: {
						title: "Esperando lectura del QR Code",
						content: "Click en el botón 'QR CODE' para obtener un nuevo QR Code",
					},
					connected: {
						title: "Conexión exitosa",
					},
					timeout: {
						title: "Se ha perdido la conexión con el teléfono móvil.",
						content: "Asegúrese de que su teléfono esté conectado a Internet y WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
					},
				},
				table: {
					name: "Nombre",
					status: "Estado",
					lastUpdate: "Última actualización",
					default: "Por defecto",
					actions: "Acciones",
					session: "Session",
					number: "Número do Whatsapp"
				},
			},
			whatsappModal: {
				title: {
					add: "Añadir WhatsApp",
					edit: "Editar WhatsApp",
				},
				form: {
					name: "Nombre",
					default: "Por defecto",
					maxUseBotQueues: "Número máximo de veces que se enviará el chatbot",
					expiresTicket: "Finalizar chats abiertos después de x horas",
					outOfHoursMessage: "Mensaje fuera de horario",
					greetingMessage: "Mensaje de bienvenida",
					complationMessage: "Mensaje de finalización",
					token: "Token para API",
				},
				buttons: {
					okAdd: "Añadir",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "WhatsApp creado con éxito!",
			},
			qrCode: {
				message: "Lea el código QR con su teléfono móvil",
			},
			contacts: {
				title: "Contactos",
				toasts: {
					deleted: "Contacto eliminado con éxito!",
				},
				searchPlaceholder: "Buscar contacto...",
				confirmationModal: {
					deleteTitle: "Borrar contacto ",
					importTitlte: "Importar contactos",
					deleteMessage: "¿Está seguro de que desea eliminar este contacto? Todas las citas relacionadas se perderán.",
					importMessage: "Desear importar los contactos de su teléfono?",
				},
				buttons: {
					import: "Importar Contacto",
					add: "Agregar Contato",
					export: "Exportar Contacto",
				},
				table: {
					name: "Nombre",
					whatsapp: "WhatsApp",
					email: "Email",
					actions: "Acciones",
				},
			},
			forwardMessage: {
				text: "Reenviar",
			},
			forwardMessageModal: {
				title: "Reenviar mensaje",
				buttons: {
					ok: "Reenviar",
				}
			},
			contactModal: {
				title: {
					add: "Añadir contato",
					edit: "Editar contato",
				},
				form: {
					mainInfo: "Datos de contacto",
					extraInfo: "Información adicional",
					name: "Nombre",
					number: "Número de Whatsapp",
					email: "Email",
					extraName: "Nombre del campo",
					extraValue: "Valor",
				},
				buttons: {
					addExtraInfo: "Añadir campo",
					okAdd: "Añadir",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Contacto guardado exitosamente!",
			},
			queueModal: {
				title: {
					add: "Añadir cola",
					edit: "Editar cola",
				},
				form: {
					name: "Nombre",
					color: "Cor",
					greetingMessage: "Mensaje de bienvenida",
					complationMessage: "Mensaje de finalización",
					outOfHoursMessage: "Mensaje fuera de horario",
					token: "Token API",
				},
				buttons: {
					okAdd: "Añadir",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
			},
			userModal: {
				title: {
				  add: "Agregar usuario",
				  edit: "Editar usuario",
				},
				form: {
				  name: "Nombre",
				  email: "Correo Electrónico",
				  password: "Contraseña",
				  farewellMessage: "Mensaje de Despedida",
				  profile: "Perfil",
				  whatsapp: "Conexión Predeterminada",
				  startWork: "Inicio de Trabajo",
				  endWork: "Fin de Trabajo"
				},
				buttons: {
				  okAdd: "Agregar",
				  okEdit: "Guardar",
				  cancel: "Cancelar",
				},
				success: "Usuario guardado exitosamente.",
			  },
			  
			companyModal: {
				title: {
					add: "Añadir empresa",
					edit: "Editar empresa",
				},
				form: {
					name: "Nombre",
					email: "Email",
					passwordDefault: "Contraseña",
					numberAttendants: "Usuarios",
					numberConections: "Conexiones",
				},
				buttons: {
					okAdd: "Añadir",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Empresa creada con éxito!",
			},
			scheduleModal: {
				title: {
					add: "Nueva Agenda",
					edit: "Editar Agenda",
				},
				form: {
					body: "Mensaje",
					contact: "Contacto",
					sendAt: "Fecha de Agenda",
					sentAt: "Fecha de Envío",
				},
				buttons: {
					okAdd: "Añadir",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Agenda creada con éxito!",
			},
			tagModal: {
				title: {
					add: "Nuevo Tag",
					edit: "Editar Tag",
				},
				form: {
					name: "Nombre",
					color: "Cor",
				},
				buttons: {
					okAdd: "Añaadir",
					okEdit: "Guardar",
					cancel: "Cancelar",
				},
				success: "Tag creado con éxito!",
			},
			ratingModal: {
				title: {
					add: "Añaadir menu de calificación",
					edit: "Editar menu de calificación",
				},
				buttons: {
					okAdd: "Guardar",
					okEdit: "Editar",
					cancel: "Cancelar",
					options: "Añadir opción",
				},
				form: {
					name: "Nombre",
					message: "Mensaje de calificación",
					options: "Opciones de calificación",
					extraName: "Nombre del campo",
					extraValue: "Valor",
				},
				success: "Calificación creada con éxito!",
			},
			chat: {
				noTicketMessage: "Selecione una Conversación",
			},
			ticketsManager: {
				buttons: {
					newTicket: "Nuevo",
				},
			},
			ticketsQueueSelect: {
				placeholder: "Colas",
			},
			tickets: {
				toasts: {
					deleted: "Conversación eliminada",
				},
				notification: {
					message: "Mensaje de",
				},
				tabs: {
					open: { title: "Abierto" },
					closed: { title: "Resueltos" },
					group: { title: "Grupos" },
					search: { title: "Buscar" },
				},
				search: {
					placeholder: "Buscar Conversación",
				},
				buttons: {
					showAll: "Todos",
				},
			},
			transferTicketModal: {
				title: "Transferir Ticket",
				fieldLabel: "Escribe para buscar usuarios",
				fieldQueueLabel: "Transferir a la cola",
				fieldQueuePlaceholder: "Selecciona una cola",
				noOptions: "No se encontraron usuarios con ese nombre",
				buttons: {
				  ok: "Transferir",
				  cancel: "Cancelar",
				},
			  },
			  
			ticketsList: {
				pendingHeader: "Esperando",
				assignedHeader: "Atendiendo",
				noTicketsTitle: "Nada aqui!",
				noTicketsMessage: "No se encontró ningún servicio con este estado o término de búsqueda",
				buttons: {
					accept: "Aceptar",
					reopen: "Reabrir",
					closed: "Cerrar",
				},
			},
			newTicketModal: {
				title: "Crear Conversación",
				fieldLabel: "Escriba para buscar un usuario",
				fieldQueuePlaceholder: "Seleccione una cola",
				add: "Añadir",
				buttons: {
					ok: "Guardar",
					cancel: "Cancelar",
				},
			},
			mainDrawer: {
				listItems: {
					dashboard: "Dashboard",
					connections: "Conexiones",
					tickets: "Chats",
					quickMessages: "Respuestas Rápidas",
					contacts: "Contactos",
					queues: "Departamentos & Chatbots",
					tags: "Tags",
					administration: "Administración",
					companies: "Empresas",
					users: "Equipo",
					settings: "Configuraciónes",
					ratings: "Formularios de Calificación",
					helps: "Centro de ayuda",
					messagesAPI: "API",
					schedules: "Agendamientos",
					campaigns: "Campañas",
					annoucements: "Informaciones",
					chats: "Chat Interno",
					financeiro: "Financiero",
					logout: "Cerrar Sesión",
					version: "Versión",
					toDoList: "Tareas",
					panel: "Panel",
					lanes: "Tableros",
				},
				appBar: {
					user: {
						profile: "Perfil",
						logout: "Salir",
					},
					refresh: "Atualizar",
				},
			},
			languages: {
				undefined: "Idioma",
				"pt-BR": "Português",
				es: "Español",
				en: "English",
				tr: "Türkçe"
			},
			messagesAPI: {
				title: "API",
				textMessage: {
					number: "Número",
					body: "Mensaje",
					token: "Token Adicionado",
				},
				mediaMessage: {
					number: "Número",
					body: "Nombre del archivo",
					media: "Archivo",
					token: "Token Adicionado",
					caption: "Legenda",
				},
			},
			notifications: {
				noTickets: "Ninguna notificación",
			},
			quickMessages: {
				title: "Respuestas Rápidas",
				buttons: {
					add: "Nueva Respuesta",
				},
				dialog: {
					shortcode: "Atajo",
					message: "Respuesta",
				},
			},
			contactLists: {
				title: "Listas de Contactos",
				table: {
					name: "Nombre",
					contacts: "Contactos",
					actions: "Acciones",
				},
				buttons: {
					add: "Nueva Lista",
				},
				dialog: {
					name: "Nombre",
					company: "Empresa",
					okEdit: "Editar",
					okAdd: "Añadir",
					add: "Añadir",
					edit: "Editar",
					cancel: "Cancelar",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "Esta seguro que desea borrar esta lista?",
				},
				toasts: {
					deleted: "Registro eliminado",
				},
			},
			contactListItems: {
				title: "Contactos",
				searchPlaceholder: "Buscar Contacto",
				buttons: {
					add: "Nueva Contacto",
					lists: "Listas",
					import: "Importar",
				},
				dialog: {
					name: "Nombre",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					okEdit: "Editar",
					okAdd: "Añadir",
					add: "Añadir",
					edit: "Editar",
					cancel: "Cancelar",
				},
				table: {
					name: "Nombre",
					number: "Número",
					whatsapp: "Whatsapp",
					email: "E-mail",
					actions: "Acciones",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "Esta a punto de borrar este contacto, esta seguro?",
					importMessage: "Desear importar los contactos?",
					importTitlte: "Importar",
				},
				toasts: {
					deleted: "Registro eliminado",
				},
			},
			campaigns: {
				title: "Campañas",
				searchPlaceholder: "Buscar Campaña",
				buttons: {
					add: "Nueva Campaña",
					contactLists: "Listas de Contactos",
				},
				table: {
					name: "Nombre",
					whatsapp: "Conexión",
					contactList: "Lista de Contatos",
					status: "Estado",
					scheduledAt: "Agendamentos",
					completedAt: "Concluído",
					confirmation: "Confirmación",
					actions: "Acciones",
				},
				dialog: {
					new: "Nuevo Campaña",
					update: "Editar Campaña",
					readonly: "Visualizar Campaña",
					form: {
						name: "Nombre",
						message1: "Mensaje 1",
						message2: "Mensaje 2",
						message3: "Mensaje 3",
						message4: "Mensaje 4",
						message5: "Mensaje 5",
						confirmationMessage1: "Mensaje de Confirmación 1",
						confirmationMessage2: "Mensaje de Confirmación 2",
						confirmationMessage3: "Mensaje de Confirmación 3",
						confirmationMessage4: "Mensaje de Confirmación 4",
						confirmationMessage5: "Mensaje de Confirmación 5",
						messagePlaceholder: "Contenido del Mensaje",
						whatsapp: "Conexión",
						status: "Estado",
						scheduledAt: "Agendamientos",
						confirmation: "Confirmación",
						contactList: "Lista de Contatos",
					},
					buttons: {
						add: "Añadir",
						edit: "Actualizar",
						okadd: "Ok",
						cancel: "Cancelar Campaña",
						restart: "Reiniciar Campaña",
						close: "Cerrar",
						attach: "Adjuntar Archivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "Esta accion no se puede deshacer, esta seguro?",
				},
				toasts: {
					success: "Operación exitosa",
					cancel: "Campaña cancelada",
					restart: "Campaña reiniciada",
					deleted: "Registro eliminado",
				},
			},
			announcements: {
				title: "Informativos",
				searchPlaceholder: "Buscar",
				buttons: {
					add: "Nuevo Informativo",
					contactLists: "Listas de Informativos",
				},
				table: {
					priority: "Prioridad",
					title: "Titulo",
					text: "Texto",
					mediaName: "Archivo",
					status: "Estado",
					actions: "Acciones",
				},
				dialog: {
					edit: "Editar Informativo",
					add: "Nuevo Informativo",
					update: "Editar Informativo",
					readonly: "Solo visualizar",
					form: {
						priority: "Prioridad",
						title: "Titulo",
						text: "Texto",
						mediaPath: "Archivo",
						status: "Estado",
					},
					buttons: {
						add: "Añadir",
						edit: "Actualizar",
						okadd: "Ok",
						cancel: "Cancelar",
						close: "Cerrar",
						attach: "Adjuntar Archivo",
					},
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "Esta accion no se puede deshacer, esta seguro?",
				},
				toasts: {
					success: "Operación exitosa",
					deleted: "Registro eliminado",
				},
			},
			campaignsConfig: {
				title: "Configuración de Campañas",
			},
			queues: {
				title: "Departamentos & Chatbots",
				table: {
					name: "Nombre",
					color: "Cor",
					greeting: "Mensaje de bienvenida",
					actions: "Acciones",
				},
				buttons: {
					add: "Añadir cola",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "¿Estás seguro? ¡Esta acción no se puede revertir! Las llamadas en esta cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
				},
			},
			queueSelect: {
				inputLabel: "Colas",
			},
			users: {
				title: "Usuários",
				table: {
					id: "ID",
					status: "Estado",
					name: "Nombre",
					email: "Email",
					profile: "Perfil",
					whatsapp: "Whatsapp por defecto",
					startWork: "Inicio de trabajo",
					endWork: "Fin de trabajo",
					actions: "Acciones",
				},
				buttons: {
					add: "Añadir usuario",
				},
				toasts: {
					deleted: "Usuario eliminado con éxito.",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "Todos los datos del usuario se perderán. Las llamadas abiertas de este usuario se moverán a la cola.",
				},
			},
			compaies: {
				title: "Empresas",
				table: {
					status: "Activo",
					name: "Nombre",
					email: "Email",
					numberAttendants: "Convenciones",
					numberConections: "Conexiones",
					value: "Valor",
					namePlan: "Nombre Plan",
					numberQueues: "Colas",
					useCampaigns: "Campañas",
					useExternalApi: "Rest API",
					useFacebook: "Facebook",
					useInstagram: "Instagram",
					useWhatsapp: "Whatsapp",
					useInternalChat: "Chat Interno",
					useSchedules: "Agendamientos",
					createdAt: "Creado en",
					dueDate: "Vencimiento",
					lastLogin: "Ult. Login",
					actions: "Acciones",
				},
				buttons: {
					add: "Añadir Empresa",
				},
				toasts: {
					deleted: "Empresa eliminado con éxito",
				},
				confirmationModal: {
					deleteTitle: "Borrar",
					deleteMessage: "Se perderán todos los datos de la empresa. Los tickets abiertos de este usuario se moverán a la cola.",
				},
			},
			helps: {
				title: "Central de Ayuda",
			},
			schedules: {
				title: "Agendamientos",
				confirmationModal: {
					deleteTitle: "¿Está seguro de que desea eliminar este Agendamiento?",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				table: {
					contact: "Contacto",
					body: "Mensaje",
					sendAt: "Fecha de Agendamiento",
					sentAt: "Fecha de Envío",
					status: "Estado",
					actions: "Acciones",
				},
				buttons: {
					add: "Nuevo Agendamiento",
				},
				toasts: {
					deleted: "Agendamento excluído com sucesso.",
				},
			},
			tags: {
				title: "Tags",
				confirmationModal: {
					deleteTitle: "¿Está seguro de que desea eliminar esta tag?",
					deleteMessage: "Esta acción no se puede revertir.",
				},
				table: {
					name: "Nombre",
					color: "Cor",
					tickets: "Registros Tagdos",
					actions: "Acciones",
				},
				buttons: {
					add: "Nueva Tag",
				},
				toasts: {
					deleted: "Tag excluída com sucesso.",
				},
			},
			ratings: {
				title: "Classificações",
				table: {
					name: "Nombre",
					contacts: "Contactos",
					actions: "Acciones",
				},
				toasts: {
					deleted: "Calificación eliminada con éxito.",
					deletedAll: "Todas las calificaciones eliminadas con éxito.",
				},
				buttons: {
					add: "Añadir Calificación",
					deleteAll: "Boorar Todas",
				},
				confirmationModal: {
					deleteTitle: "Boorar",
					deleteAllTitle: "Borar Todas",
					deleteMessage: "Ten cuidado, esta acción no se puede deshacer. ¿Estás seguro?",
					deleteAllMessage: "¿Está seguro de que desea eliminar todas las calificaciones?",
				},
			},
			settings: {
				options: {
					title: "Opciones",
					enabled: "Activado",
					disabled: "Desactivado",
					officeScheduling: "Programación de oficina",
					queueSectorManagement: "Gestión de colas/sectores",
					managementByCompany: "Gestión por empresa",
					sendGreetingAcceptingService: "Enviar saludo al aceptar el servicio",
					chooseRandomOperatorWhenChoosingSector: "Elija operador aleatorio al elegir sector",
					sendSectorAttendantTransferMessage: "Enviar mensaje de transferencia de sector/asistente",
					ignoreGroupMessages: "Ignorar mensajes grupales",
					botType: "Tipo de Bot",
					notAcceptCallsOnWhatsApp: "Informar que no acepta llamadas en WhatsApp",
					allowsAttendantChooseSendSignature: "Permite al operador elegir ENVIAR Firma",
					sendGreetingWhenOneSector: "Enviar saludo cuando solo hay 1 sector",
					sendMessageWithQueuePosition: "Enviar mensaje con posición en la cola",
					sendFarewellMessageWhenWaiting: "Enviar mensaje de despedida al esperar",
					agreeReceiveAudioFromAllContacts: "Aceptar recibir audio de todos los contactos",
					wordRestartFlow: "Palabra para reiniciar el flujo",
				},
				success: "Configuración guardada con éxito.",
				title: "Configuraciones",
				settings: {
					userCreation: {
						name: "Crear usuarios",
						options: {
							enabled: "Activado",
							disabled: "Desactivado",
						},
					},
				},
			},
			messagesList: {
				header: {
					assignedTo: "Asignado a: ",
					dialogRatingTitle: "¿Quieres dejar una reseña de servicio al cliente?",
					dialogClosingTitle: "Finalizar Conversación con el Cliente",
					dialogRatingCancel: "Resolver con mensaje de despedida",
					dialogRatingSuccess: "Resolver y enviar Calificación",
					dialogRatingWithoutFarewellMsg: "Resolver sin mensaje de despedida",
					ratingTitle: "Elija un menú de Calificación",
					buttons: {
						return: "Devolver",
						resolve: "Resolver",
						reopen: "Reabrir",
						accept: "Aceptar",
						rating: "Enviar Calificación",
					},
				},
				deleted: "Este mensaje fue eliminado por el contacto.",
				metaPolice: "Tienes 24 horas para responder después de recibir un mensaje, siguiendo las políticas de Meta.",
				missedCall: "Llamada de voz/video perdida en",
				greetingsLead: "¡Saludos al nuevo Lead!",
			},
			messagesInput: {
				placeholderOpen: "Escriba un mensaje",
				placeholderClosed: "Reabrir o aceptar este ticket para enviar un mensaje.",
				signMessage: "Firmar",
			},
			contactDrawer: {
				header: "Detalles de contacto",
				buttons: {
					edit: "Editar contato",
				},
				extraInfo: "Outras informações",
			},
			ticketOptionsMenu: {
				schedule: "Agendamiento",
				delete: "Borrar",
				transfer: "Transferir",
				registerAppointment: "Notas de contacto",
				resolveWithNoFarewell: "Final sin despedida",
				acceptAudioMessage: "¿Aceptar audio del contacto?",
				appointmentsModal: {
					title: "Notas de contacto",
					textarea: "Observación",
					placeholder: "Ingresa los datos que deseas registrar aquí",
				},
				confirmationModal: {
					title: "Eliminar conversación de contacto",
					message: "¡Atención! Todos los mensajes relacionados con el ticket se perderán.",
				},
				buttons: {
					delete: "Borrar",
					cancel: "Cancelar",
				},
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancelar",
				},
			},
			messageOptionsMenu: {
				delete: "Borrar",
				reply: "Responder",
				forward: "Reenviar",
				toForward: "Reenviar",
				talkTo: "Hablar con",
				confirmationModal: {
					title: "¿Borrar mensaje?",
					message: "Esta acción no se puede revertir.",
				},
			},
			backendErrors: {
				ERR_NO_OTHER_WHATSAPP: "Debe haber al menos un WhatsApp predeterminado.",
				ERR_NO_DEF_WAPP_FOUND: "No se encontró WhatsApp predeterminado. Revisa la página de conexiones.",
				ERR_WAPP_NOT_INITIALIZED: "Esta sesión de WhatsApp no ​​se ha inicializado. Revisa la página de conexiones.",
				ERR_WAPP_CHECK_CONTACT: "No se puede verificar el contacto de WhatsApp. Revisa la página de conexiones",
				ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
				ERR_WAPP_DOWNLOAD_MEDIA: "No se pueden descargar medios de WhatsApp. Consulte la página de conexiones.",
				ERR_INVALID_CREDENTIALS: "Error de autenticación. Inténtalo de nuevo.",
				ERR_SENDING_WAPP_MSG: "Error al enviar mensaje de WhatsApp. Revisa la página de conexiones.",
				ERR_DELETE_WAPP_MSG: "No se puede eliminar el mensaje de WhatsApp.",
				ERR_OTHER_OPEN_TICKET: "Ya hay un conversación abierto para este contacto.",
				ERR_SESSION_EXPIRED: "Sesión expirada. Por favor entre.",
				ERR_USER_CREATION_DISABLED: "El administrador ha inhabilitado la creación de usuarios.",
				ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso..",
				ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
				ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
				ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
				ERR_NO_TICKET_FOUND: "No se encontraron conversación con esta ID.",
				ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
				ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
				ERR_CREATING_MESSAGE: "Error al crear mensaje en la base de datos.",
				ERR_CREATING_TICKET: "Error al crear ticket en la base de datos.",
				ERR_FETCH_WAPP_MSG: "Error al buscar el mensaje en WhatsApp, tal vez sea demasiado antiguo.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS: "Este color ya está en uso, elija otro.",
				ERR_WAPP_GREETING_REQUIRED: "El mensaje de saludo es obligatorio cuando hay más de una fila.",
				ERR_OUT_OF_HOURS: "¡Fuera del horario de oficina!",
			},
			toDoList: {
				newTask: "Nueva Tarea",
				description: "Descripción"
			},
			commom: {
				title: "Título",
				text: "Texto",
				add: "Agregar",
				edit: "Editar",
				delete: "Borrar",
				save: "Guardar",
				close: "Cerrar",
				new: "Nuevo",
				cancel: "Cancelar",
				done: "Concluido",
				confirm: "Confirmar",
				name: "Nombre",
				action: "Acción",
				actions: "Comportamiento",
				search: "Buscar",
				opened: "En abierto",
				no: "No",
				yes: "Sí",
				user: "Usuario",
				users: "Usuarios",
				connection: "Conexión",
				connections: "Conexiones",
				queue: "Cola",
				queues: "Colas",
				updating: "Actualizando",
				view: "Ver",
				requiredField: "Campo obligatorio",
			},

			"Login": "Acceso",
      "Name": "Nombre",
			"Email": "Correo Electrónico",
			"Password": "Contraseña",
      "Phone": "Teléfono",
			"Enter": "Ingresar",
			"Send": "Enviar",
			"Recover Password?": "¿Recuperar contraseña?",
			"Recover Password": "Recuperar contraseña",
			"Required field": "Campo requerido",
			"Don't have an account? Register!": "¿No tienes una cuenta? ¡Registro!",
			"Verification Code": "Código de Verificación",
			"New Password": "Nueva Contraseña",
			"Confirm the Password": "Confirmar la Contraseña",
			"Support": "Soporte",
			"Password reset successfully": "Restablecimiento de contraseña exitoso",
			"Invalid email": "Correo electrónico inválido",
			"Passwords do not match": "Las contraseñas no coinciden",
			"Assigned to": "Asignado a",
			"Spy Conversation": "Conversación de espías",
			"No queue": "Sin cola",
			"closed": "cerrado",
			"date_format": "dd/MM/yyyy",
			"date_format_moment": "DD/MM/YYYY",
			"hour_format": "HH:mm",
			"No registry": "Sin registro",
			"Your browser does not support the video element": "Su navegador no soporta el elemento de vídeo",
			"This company does not have permission to access this page! We are redirecting you": "¡Esta empresa no tiene permiso para acceder a esta página! Te estamos redireccionando",
			"Are you sure? All built-in options will also be deleted":"¿Estás seguro? También se eliminarán todas las opciones integradas.",
      "Plan": "Plan",
      "Plans": "Planes",
      "Active": "Activa",
      "Yes": "Sí",
      "No": "No",
      "Document": "Documento",
      "Price": "Precio",
      "Due Date": "Fecha de Vencimiento",
      "Last Login": "Último acceso",
      "Created at": "Creado en",
      "Recurrence": "Reaparición",
      "Monthly": "Mensual",
      "Bimonthly": "Bimensual",
      "Quarterly": "Trimestral",
      "Semiannual": "Semi anual",
      "Annual": "Anual",
      "Clear": "Limpiar",
      "Delete": "Borrar",
      "Date": "Fecha",
      "User": "Usuario",
      "Users": "Usuarios",
      "Save": "Guardar",
      "Increment Due Date": "Fecha Vencimiento",
      "Updated successfully": "Actualizado exitosamente",
      "Saved successfully": "Guardado exitosamente",
      "Deleted Successfully": "Eliminado exitosamente",
      "Unable to complete this action": "No se puede completar esta acción",
      "Do you really want to delete?": "¿Realmente quieres eliminar?",
      "Unable to load list": "No se puede cargar la lista",
      "Check if a company with the same name already exists or if the fields have been filled in correctly": "Comprueba si ya existe una empresa con el mismo nombre o si los campos se han rellenado correctamente",
      "Connection": "Conexión",
      "Connections": "Conexiones",
      "Queue": "Cola",
      "Queues": "Colas",
      "Enabled": "Activado",
      "Disabled": "Desactivado",
      "Campaign": "Campaña",
      "Campaigns": "Campañas",
      "Schedule": "Agendamiento",
      "Schedules": "Agendamientos",
      "Internal Chat": "Chat interno",
      "External API": "API externa",
      "Company": "Empresa",
      "Companies": "Empresas",
      "Help": "Ayuda",
      "Timetables updated successfully": "Horarios actualizados con éxito",
      "Add Company/User": "Agregar empresa/usuario",
      "Video Code": "Código de vídeo",
      "Title": "Título",
      "Description": "Descripción",
      "Listing": "Listado",
      "Settings": "Configuraciones",
      "Contact Lists": "Listas de contactos",
	  "day": "día",
	  "days": "días",
      "Day of the Week": "Dia de la semana",
      "Start Time": "Hora de inicio",
      "End Time": "Hora de finalización",
      "Monday": "Lunes",
      "Tuesday": "Martes",
      "Wednesday": "Miércoles",
      "Thursday": "Jueves",
      "Friday": "Viernes",
      "Saturday": "Sábado",
      "Sunday": "Domingo",
      "Message": "Mensaje",
      "The message is mandatory to move to the next level": "El mensaje es obligatorio para pasar al siguiente nivel.",
      "If the message is not defined, the bot will not follow you": "Si el mensaje no está definido, el bot no te seguirá",
      "File": "Archivo",
      "Service Hours": "Horas de servicio",
      "Queue Data": "Datos de cola",
      "Click save to record the changes": "Haga clic en guardar para registrar los cambios.",
      "Option not saved, save option before adding a file": "Opción no guardada, guarde la opción antes de agregar un archivo",
      "File added": "Archivo agregado",
      "Queue saved successfully!": "¡Cola guardada exitosamente!",
      "Too Short!": "¡Demasiado corto!",
      "Too Long!": "¡Demasiado largo!",
      "Add options": "Agregar opciones",
      "Documentation for sending messages": "Documentación para enviar mensajes",
      "Sending Methods": "Métodos de Envio",
      "Text Messages": "Mensajes de texto",
      "Media Messages": "Mensajes de los medios",
      "Instructions": "Instrucciones",
      "Important Notes": "Notas importantes",
      "Before sending messages, it is necessary to register the token linked to the connection that will send the messages": "Antes de enviar mensajes, es necesario registrar el token vinculado a la conexión que enviará los mensajes",
      "To register, access the Connections menu, click on the connection's edit button and insert the token in the appropriate field": "Para registrarse, acceda al menú Conexiones, haga clic en el botón editar conexión e inserte el token en el campo correspondiente",
      "The sending number must not have a mask or special characters and must be composed of": "El número de envío no debe tener máscara ni caracteres especiales y debe estar compuesto por",
      "Country Code": "Código de país",
      "Number": "Número",
      "Below is the list of information needed to send text messages": "A continuación se muestra la lista de información necesaria para enviar mensajes de texto",
      "Below is the list of information needed to send media messages": "A continuación se muestra la lista de información necesaria para enviar mensajes multimedia.",
      "Sending Test": "Prueba de envío",
      "Method": "Método",
      "Registered token": "Token cadastrado",
      "text": "texo",
      "subtitle": "subtitular",
      "Body": "Mensaje",
      "Caption": "Subtitular",
      "Invalid Email!": "¡Email inválido!",
      "Updating": "Actualizando",
      "Shows all Messages for the User Profile": "Muestra todos los mensajes del perfil de usuario",
      "Disable chatbot for this contact": "Deshabilitar chatbot para este contacto",
      "Import / Export": "Importación / exportación",
      "Import from WhatsApp": "Importar desde WhatsApp",
      "Import from Excel": "Importar desde Excel",
      "Logo updated successfully!": "¡Logotipo actualizado exitosamente!",
	  "Background image updated successfully!": "¡Imagen de fondo actualizada exitosamente!",
      "Please upload a JPG, JPEG or PNG file": "Sube un archivo JPG, JPEG o PNG",
      "Please upload a file less than 1 MB": "Por favor sube un archivo de menos de 1 MB",
      "Integrations": "Integraciones",
      "This integration is disabled": "Esta integración está deshabilitada",
      "Successfully enabled": "Habilitado exitosamente",
      "Successfully disabled": "Desactivado con éxito",
      "The recommended image size is": "El tamaño de imagen recomendado es",
      "Shows Signup Link on Login/Reset Password Page": "Muestra el enlace de registro en la página login",
      "Background Image on Login Page": "Imagen de fondo en la página de inicio de sesión",
	  "Your subscription expires in": "Tu suscripción caduca en",
	  "Oops! Your subscription expired on": "¡Ups! Tu suscripción expiró el",
	  "Contact Support for more information!": "¡Comuníquese con Soporte para obtener más información!",
      "":"",

			internalChat: "Conversación",
			service: "Servicio",
			services: "Servicios",
			durationServices: "Duración de los servicios",
			groupService: "Servicio grupal",
			resetConnection: "Restablecer conexiones",
			callSupport: "Llamar a soporte",
			newConnection: "Nueva conexión",
			filterByUsers: "Filtrar por Usuarios",
			companyNoPermissionAccessPage: "¡Esta empresa no tiene permiso para acceder a esta página! Estamos redirigiendo.",
			deleteConversation: "Eliminar la conversación",
			confirmAction: "Esta acción no se puede revertir. ¿Quieres confirmar?",
			newLane: "Nuevo Tablero",
			invoice: "Factura",
			invoices: "Facturas",
			detail: "Detalle",
			details: "Detalles",
			price: "Precio",
			prices: "Precios",
			dueDate: "Fecha de Venc.",
		},
	},
};

export { messages };
